
import LockIcon from '@/assets/images/home/lock.svg';
import { isBundleRegions } from '@/utils/bundleRegions';

export default {
  name: 'FooterSimple',
  components: {
    LockIcon
  },
  props: {
    withModalTerms: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showModalTerms: false
    };
  },

  computed: {
    isBundleRegions() {
      return isBundleRegions();
    }
  }
};
