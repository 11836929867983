
import { mapGetters } from 'vuex';

import PetloveLogoPrincipal from '@/assets/images/petlove-logo-principal.svg';
import URLS from '@/models/Url';
import AuthService from '@/services/auth';
import Gtm from '@/utils/gtm';
import { HeadHome } from '@/utils/heads';
import { navigateToCheckout, persistNavigationQuery } from '@/utils/navigation';

const authService = new AuthService();
export default {
  components: {
    PetloveLogoPrincipal
  },
  props: {
    mobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      HeadHome,
      showMenu: false,
      user: null
    };
  },
  computed: {
    ...mapGetters({
      memberFromBroker: 'getMemberFromBroker',
      partnerWhitelabelConfig: 'getPartnerWhitelabelConfig'
    }),
    dashUrl() {
      return URLS().DASH_URL;
    },
    ecommerceUrl() {
      return URLS().ECOM_URL;
    },
    partnerUrl() {
      return URLS().PARTNERS_URL;
    },
    guestRegion() {
      return this.$store.getters.getGuestRegion;
    },
    guestRegionName() {
      return this.guestRegion?.name || '';
    },
    showMobileBuyButton() {
      return !this.$route.fullPath.includes('rede-credenciada');
    }
  },
  mounted() {
    this.$root.$on('user:logged', () => {
      this.user = authService.getUser();
    });

    if (authService.isLogged()) {
      this.user = authService.getUser();
    }

    /**
     * For mobile:  Make the buy button fixed on header,
     * after the buy button from home's first section
     * is hidden by scrolling.
     */
    window.onscroll = () => {
      const mobileBuyButton = document.getElementById(
        'mobile-header-buy-button'
      );
      const homeBuyButton = document.getElementById('home-buy-button');
      const currentPath = this.$route.path;
      const isHome =
        currentPath.length === 1 ||
        (currentPath.length > 1 && currentPath[1] === '#');

      if (mobileBuyButton && homeBuyButton && isHome) {
        const showButton = homeBuyButton.offsetTop;

        if (window.scrollY >= showButton) {
          mobileBuyButton.classList.remove('hide');
        } else {
          mobileBuyButton.classList.add('hide');
        }
      }
    };

    /**
     * To avoid bans from the search engines, we show the modal only on the first movement of the user,
     * instead of doing so when the page is first loaded.
     */
    window.addEventListener('scroll', () => {
      if (
        this.guestRegion?.firstAccess &&
        !this.$route.fullPath.includes('rede-credenciada') &&
        !this.$route.fullPath.includes('transparencia')
      ) {
        this.$nuxt.$emit('show:modal', 'selectRegion');
      }
    });
  },
  methods: {
    toContrate(from) {
      Gtm.sendCustomEvent(
        'click',
        'contratar-head:botao:contratar-plano-pet',
        'lp-saude'
      );

      if (this.$route.path === '/transparencia') {
        location.href = navigateToCheckout('contrate', {
          params: {
            from,
            ...this.$route.query
          }
        });
        return;
      }

      location.href = navigateToCheckout({
        params: {
          from,
          ...this.$route.query
        }
      });
    },
    openMenuMobile() {
      this.$root.$emit('open:menu-mobile');
    },
    logout() {
      authService.logout();
      this.user = null;
    },
    goToPlanSection($event) {
      $event.preventDefault();
      const scroll = () => {
        this.$scrollTo('#planos-de-saude', 700, {
          offset: -104,
          x: false,
          y: true
        });
      };

      if (this.$route.path !== '/') {
        this.$router.push({
          path: '/',
          query: persistNavigationQuery(this.$route.query)
        });
        setTimeout(() => scroll(), 300);
      } else {
        scroll();
      }
    },
    headerLink() {
      const query = persistNavigationQuery(this.$route.query);

      const queryString = new URLSearchParams(query).toString();

      return this.$route.path === '/' ? this.ecommerceUrl : `/?${queryString}`;
    }
  }
};
