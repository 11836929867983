import { render, staticRenderFns } from "./ModalVideoYouTube.vue?vue&type=template&id=5dae1fff&scoped=true"
import script from "./ModalVideoYouTube.vue?vue&type=script&lang=js"
export * from "./ModalVideoYouTube.vue?vue&type=script&lang=js"
import style0 from "./ModalVideoYouTube.vue?vue&type=style&index=0&id=5dae1fff&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dae1fff",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CaLoading: require('/app/node_modules/@petlove/caramelo-vue-components/src/Loading/index.vue').default,CaIconButton: require('/app/node_modules/@petlove/caramelo-vue-components/src/IconButton/index.vue').default})
