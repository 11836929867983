import { render, staticRenderFns } from "./Navbar.vue?vue&type=template&id=1dcb6a5f&scoped=true"
import script from "./Navbar.vue?vue&type=script&lang=js"
export * from "./Navbar.vue?vue&type=script&lang=js"
import style0 from "./Navbar.vue?vue&type=style&index=0&id=1dcb6a5f&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1dcb6a5f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SubMenu: require('/app/components/header/SubMenu.vue').default,CaIconButton: require('/app/node_modules/@petlove/caramelo-vue-components/src/IconButton/index.vue').default,CaButton: require('/app/node_modules/@petlove/caramelo-vue-components/src/Button/index.vue').default})
