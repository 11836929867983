
const MODAL_CONTENT = {
  health4pet: {
    title: 'Health4Pet e Petlove!'
  },
  portopet: {
    title: 'Porto Pet e Petlove!'
  },
  nofaro: {
    title: 'Nofaro e Petlove!'
  }
};

export default {
  data() {
    return {
      show: false,
      content: {
        title: ''
      }
    };
  },
  mounted() {
    this.veryifyAndShowUtmSource();
  },
  methods: {
    showModal() {
      this.show = true;
    },
    confirmModal() {
      this.$emit('close');
      this.show = false;
    },
    veryifyAndShowUtmSource() {
      const utmSource = this.$route.query.utm_source;
      if (MODAL_CONTENT[utmSource]) {
        this.content = MODAL_CONTENT[utmSource];
        this.showModal();
      }
    }
  }
};
