import { render, staticRenderFns } from "./FooterSimple.vue?vue&type=template&id=c8676b0a&scoped=true"
import script from "./FooterSimple.vue?vue&type=script&lang=js"
export * from "./FooterSimple.vue?vue&type=script&lang=js"
import style0 from "./FooterSimple.vue?vue&type=style&index=0&id=c8676b0a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8676b0a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CaButton: require('/app/node_modules/@petlove/caramelo-vue-components/src/Button/index.vue').default,CaLink: require('/app/node_modules/@petlove/caramelo-vue-components/src/Link/index.vue').default,CompanyLegal: require('/app/components/footer/CompanyLegal.vue').default,ModalBuyTerms: require('/app/components/pages/Buy/ModalBuyTerms.vue').default})
