export default {
  currentPromise: null,

  getBanner(ctx) {
    if (this.currentPromise) {
      return this.currentPromise;
    }

    this.currentPromise = ctx.$axios
      .get('/campaign', {
        headers: {
          'Cache-Control': 'no-store'
        }
      })
      .then((res) => {
        if (res.status !== 200) return { campaign: {} };
        return res.data.data;
      })
      .finally(() => {
        this.currentPromise = null;
      });

    return this.currentPromise;
  }
};
