import UserService from '@/services/user';

const userService = new UserService();

export const state = () => ({
  cardBin: null,
  newCreditCard: false,
  orderValues: null,
  payment: {
    method: '',
    cardSelected: null,
    credit_card: {
      nome: '',
      numero: '',
      validade: '',
      cvv: ''
    },
    bank_debit: {
      banco: '',
      agencia: '',
      conta: '',
      nome: '',
      cpf: ''
    },
    annually: 1
  },
  paymentMethods: [],
  selectedPaymentCard: {},
  userAuthenticatedCards: [],
  isIframe: false
});

export const getters = {
  getBilledAnnually: (state) => state.payment?.annually,
  getCardBin: (state) => state.cardBin,
  getNewCreditCard: (state) => state.newCreditCard,
  getOrderValues: (state) => state.orderValues,
  getDiscountPass: (state) => state.orderValues.discountPass,
  getPassCpfRegistered: (state) => state.orderValues.passCpfRegistered,
  getPayment: (state) => state.payment,
  getCardSelected: (state) => state.payment?.cardSelected,
  getCreditCardDetails: (state) => state.payment?.credit_card,
  getBankDebitDetails: (state) => state.payment?.bank_debit,
  getPaymentMethod: (state) => state.payment?.method,
  getPaymentMethods: (state) => state.paymentMethods,
  getSelectedPaymentCard: (state) => state.selectedPaymentCard,
  getUserAuthenticatedCards: (state) => state.userAuthenticatedCards,
  getIsIframe: (state) => state.isIframe
};

export const mutations = {
  set_billedAnnually(state, value) {
    state.payment.annually = value;
  },
  set_cardBin(state, value) {
    state.cardBin = value;
  },
  set_newCreditCard(state, value) {
    state.newCreditCard = value;
  },
  set_orderValues(state, value) {
    state.orderValues = value;
  },
  set_Pass(state, { discountPass, isPass }) {
    state.orderValues.discountPass = discountPass;
    state.orderValues.passCpfRegistered = isPass;
  },
  set_payment(state, value) {
    state.payment = value;
  },
  set_paymentMethod(state, value) {
    state.payment.method = value;
  },
  set_cardSelected(state, value) {
    state.payment.cardSelected = value;
  },
  set_creditCardDetails(state, value) {
    state.payment.credit_card = value;
  },
  set_bankDebitDetails(state, value) {
    state.payment.bank_debit = value;
  },
  set_paymentMethods(state, value) {
    state.paymentMethods = value;
  },
  set_selectedPaymentCard(state, value) {
    state.selectedPaymentCard = value;
  },
  set_userAuthenticatedCards(state, value) {
    state.userAuthenticatedCards = value;
  },
  set_isIframe(state, value) {
    state.isIframe = value;
  }
};

export const actions = {
  clearCreditCardData({ getters, commit }) {
    const payment = getters.getPayment;
    commit('set_payment', {
      ...payment,
      cardSelected: null,
      credit_card: {
        nome: '',
        numero: '',
        validade: '',
        cvv: ''
      }
    });
  },
  async getPaymentCard({ commit }) {
    await userService
      .getUserPaymentCards(this)
      .then((data) => {
        commit('set_userAuthenticatedCards', data);
        this.userAuthenticatedCards = data;
        commit('set_selectedPaymentCard', data[data.length - 1]);
      })
      .catch((error) => {
        console.error(error);
        this.$nuxt?.$honeybadger?.notify(
          `Fluxo de contratação (busca os cartões do usuário logado) - ${error} - ${new Date()}`,
          {
            tags: ['critical']
          }
        );
        commit('set_userAuthenticatedCards', []);
      });
  }
};
