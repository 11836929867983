export default {
  getParnerConfig(ctx, utmSource) {
    return ctx.$axios
      .get('partner/page/config', { params: { utm_source: utmSource } })
      .then((res) => res.data)
      .catch(() => {
        return {
          hide_inside_sales_phone: false,
          hide_partner_menu_options: false,
          hide_whatsapp_button: false
        };
      });
  }
};
