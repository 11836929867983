import additionalServices from '@/services/additionalServices';
import utils from '@/utils/utils';

const state = () => ({
  discountClubData: {},
  discountClubPrice: undefined,
  discountClubEcommercePrice: 17.99
});

const getters = {
  getDiscountClub: (state) => state.discountClubData,
  getDiscountClubPrice: (state) =>
    parseFloat(state.discountClubData.base_price),
  getDiscountClubEcommercePrice: (state) => state.discountClubEcommercePrice,
  getDiscountClubPriceLabel(state) {
    if (typeof window === 'undefined') {
      return;
    }
    return window.$nuxt.$store.getters['payment/getBilledAnnually']
      ? `R$ ${utils.currency(state.discountClubPrice * 12)}/ano`
      : `R$ ${utils.currency(state.discountClubPrice)}/mês`;
  },
  getDiscountClubEcommercePriceLabel(state) {
    if (typeof window === 'undefined') {
      return;
    }
    return window.$nuxt.$store.getters['payment/getBilledAnnually']
      ? `R$ ${utils.currency(state.discountClubEcommercePrice * 12)}/ano`
      : `R$ ${utils.currency(state.discountClubEcommercePrice)}/mês`;
  },
  getMonthDiscountClubPriceLabel(state) {
    return `R$ ${utils.currency(state.discountClubPrice)}/mês`;
  },
  getMonthDiscountClubEcommercePriceLabel(state) {
    return `R$ ${utils.currency(state.discountClubEcommercePrice)}/mês`;
  },
  getDiscountClubDisabledRegions: (state) =>
    state.discountClubData?.regions_not_enables
};

const mutations = {
  set_discountClubData(state, value) {
    state.discountClubData = value;
  }
};

const actions = {
  async fetchAdditionalServices({ state, commit }, type) {
    const response = await additionalServices.getAdditionalServices(this, type);
    commit('set_discountClubData', response.data[0]);
    state.discountClubPrice = parseFloat(response.data[0].base_price);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
