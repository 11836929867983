export const OFFER_BATH = [
  {
    name: 'Oferece',
    id: 1
  },
  {
    name: 'Não Oferece',
    id: 2
  }
];
