import Vue from 'vue';
const BASE64 = 'base64';
const UTF8 = 'utf8';
const SHA_256 = 'SHA-256';
const BASE_STRING_16 = 16;

export default {
  validateCPF(cpf) {
    if (!cpf || cpf === '') {
      return false;
    }
    cpf = cpf.replace(/[^\d]+/g, '');
    // Elimina CPFs inválidos conhecidos
    if (
      cpf.length !== 11 ||
      cpf === '00000000000' ||
      cpf === '11111111111' ||
      cpf === '22222222222' ||
      cpf === '33333333333' ||
      cpf === '44444444444' ||
      cpf === '55555555555' ||
      cpf === '66666666666' ||
      cpf === '77777777777' ||
      cpf === '88888888888' ||
      cpf === '99999999999'
    ) {
      return false;
    }
    // Valida 1o digito
    let add = 0;
    for (let i = 0; i < 9; i++) {
      add += parseInt(cpf.charAt(i)) * (10 - i);
    }
    let rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) {
      rev = 0;
    }
    if (rev !== parseInt(cpf.charAt(9))) {
      return false;
    }
    // Valida 2o digito
    add = 0;
    for (let i = 0; i < 10; i++) {
      add += parseInt(cpf.charAt(i)) * (11 - i);
    }
    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) {
      rev = 0;
    }
    return rev === parseInt(cpf.charAt(10));
  },

  currency(value) {
    let v = parseFloat(value).toFixed(2) + '';
    v = v.replace(/\./g, '');
    v = v.replace(/(\d{1,2})$/, ',$1');
    v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    v = v !== '' ? '' + v : '';
    return v;
  },

  distance(value) {
    let label = parseFloat(value / 1000).toFixed(0) + 'km';
    if (value < 1000) {
      label = value.toFixed(0) + 'm';
    } else if (value < 5000) {
      label = parseFloat(value / 1000).toFixed(1) + 'km';
    }
    return label.replace('.', ',');
  },

  formatDate(datetime) {
    const daysLabels = {
      0: 'Dom',
      1: 'Seg',
      2: 'Ter',
      3: 'Qua',
      4: 'Qui',
      5: 'Sex',
      6: 'Sáb'
    };

    const date = new Date(datetime);
    const label = daysLabels[date.getDay()];
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();

    return label + ' - ' + day + '/' + month + '/' + year;
  },

  discountInstallment(value) {
    if (value > 1) {
      return ' nas ' + value + ' primeiras mensalidades';
    }
    return ' na 1ª mensalidade';
  },

  scrollToTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  },

  /**
   * example: shade(#49A971, 0.25)
   * @param color (in HEX form)
   * @param percent (between -1 and 1)
   * @returns {string} hex-color darkened or lightened
   */
  shade(color, percent) {
    const f = parseInt(color.slice(1), 16);
    const t = percent < 0 ? 0 : 255;
    const p = percent < 0 ? percent * -1 : percent;
    const R = f >> 16;
    const G = (f >> 8) & 0x00ff;
    const B = f & 0x0000ff;
    return (
      '#' +
      (
        0x1000000 +
        (Math.round((t - R) * p) + R) * 0x10000 +
        (Math.round((t - G) * p) + G) * 0x100 +
        (Math.round((t - B) * p) + B)
      )
        .toString(16)
        .slice(1)
    );
  },

  getErrorMessage(response, defaultMessage = 'Ocorreu um erro inesperado') {
    let message = defaultMessage;

    if (response?.response?.data?.errors) {
      message = '';
      for (const key in response.response.data.errors) {
        message += response.response.data.errors[key] + '\n';
      }
    } else if (response?.response?.data?.message) {
      message = response.response.data.message;
    }

    return message;
  },
  removeEspecialCharacters(str) {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036F]/g, '')
      .replace(/[^a-zA-Z0-9 ]/g, '');
  },
  decodeBase64(value) {
    return Buffer.from(value, BASE64).toString(UTF8);
  },

  formatCNPJ(cnpj) {
    cnpj = cnpj?.replace(/[^\d]+/g, '');
    return cnpj?.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
      '$1.$2.$3/$4-$5'
    );
  },
  async generateSHA256Hash(value) {
    try {
      const msgUint8 = new TextEncoder().encode(value);
      const hashBuffer = await window.crypto.subtle.digest(SHA_256, msgUint8);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray
        .map((item) => item.toString(BASE_STRING_16).padStart(2, '0'))
        .join('');
      return hashHex;
    } catch (error) {
      Vue.prototype.$honeybadger.notify(error);
      return '';
    }
  },
  deepEqual(obj1, obj2) {
    if (obj1 === obj2) return true;

    if (
      typeof obj1 !== 'object' ||
      obj1 === null ||
      typeof obj2 !== 'object' ||
      obj2 === null
    ) {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (const key of keys1) {
      if (!keys2.includes(key) || !this.deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  }
};
