import createPersistedState from 'vuex-persistedstate';

import { getItem, removeItem } from '@/utils/storage';

const storageKey = 'vuexV3';

transferLegacyStorage();

export default ({ store }) => {
  createPersistedState({
    key: storageKey,
    reducer: (state) => {
      return {
        indication: state.user.indication,
        selectedPlanAtHomeId: state.user.selectedPlanAtHomeId,
        promoCode: state.promoCode,
        partnerCode: state.partnerCode,
        pass: state.pass,
        guestRegion: state.guestRegion,
        user: state.user,
        isSetAlertCookie: state.isSetAlertCookie,
        isDiscountClub: state.isDiscountClub,
        userType: state.userType,
        variantClubPrice: state.variantClubPrice,
        isPromotionRegion: state.isPromotionRegion
      };
    },
    storage: {
      storage: window.sessionStorage,
      getItem: (key) => window.sessionStorage.getItem(key),
      setItem: (key, value) => window.sessionStorage.setItem(key, value),
      removeItem: (key) => window.sessionStorage.removeItem(key)
    }
  })(store);
};

function transferLegacyStorage() {
  transferItemFromLocalToSessionStorage('utm_source');
  transferItemFromLocalToSessionStorage('track_just_purchased');

  if (getItem(storageKey)) {
    window.sessionStorage.setItem(storageKey, getItem(storageKey));
    removeItem(storageKey);
  }
}

function transferItemFromLocalToSessionStorage(key) {
  const value = window.localStorage.getItem(key);
  if (value) {
    window.sessionStorage.setItem(key, value);
    window.localStorage.removeItem(key);
  }
}
