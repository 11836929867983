
import { mapGetters } from 'vuex';

import { OwnPlansPartners } from '@/models/OwnPlansPartners';
import URLS from '@/models/Url';
import { isBundleRegions } from '@/utils/bundleRegions';
import Gtm from '@/utils/gtm';
import { navigateToCheckout, persistNavigationQuery } from '@/utils/navigation';

export default {
  name: 'MenuMobile',

  computed: {
    ...mapGetters({
      guestRegion: 'getGuestRegion',
      isItau: 'getIsItau',
      memberFromBroker: 'getMemberFromBroker',
      hidePartnerMenuOptions: 'getHidePartnerMenuOptions',
      partnerWhitelabelConfig: 'getPartnerWhitelabelConfig',
      showBundleAddOn: 'getShowBundleAddOn',
      promoCode: 'getPromoCode'
    }),
    toCustomersSpace() {
      return URLS().DASH_URL;
    },
    toPartnerUrl() {
      return URLS().PARTNERS_URL;
    },
    normalizedPhone() {
      return this.guestRegion?.st_cellphone?.replace(/\D/g, '');
    },
    showPetlovePlans() {
      return !this.isItau && !this.memberFromBroker;
    },
    showSalesInfo() {
      return (
        !this.isItau &&
        !this.memberFromBroker &&
        !this.partnerWhitelabelConfig.hideInsideSalesPhone
      );
    },
    isAccreditNetworkPage() {
      return this.$route.fullPath.includes('rede-credenciada');
    }
  },

  watch: {
    guestRegion(value, oldValue) {
      if (value !== oldValue) this.afterSetRegion();
    }
  },
  mounted() {
    document.documentElement.classList.add('is-clipped');

    this.$nuxt.$on('show:modal', (modal) => {
      if (modal === 'selectRegion') this.close();
    });
  },
  beforeDestroy() {
    document.documentElement.classList.remove('is-clipped');
  },

  methods: {
    close() {
      this.$emit('closeSidebar');
    },
    afterSetRegion() {
      this.$store.commit('set_user_attribute', { key: 'pets', value: [] });
    },
    goToPlanSection() {
      Gtm.sendCustomEvent(
        'click',
        'menu:servicos:botao:planos-de-saude',
        'menu-lp'
      );
      this.close();
      if (this.$route.path !== '/') {
        this.$router.push({
          path: '/',
          query: persistNavigationQuery(this.$route.query)
        });
        setTimeout(() => this.$scrollTo('#planos-de-saude'), 300);
      } else {
        this.$scrollTo('#planos-de-saude');
      }
    },
    goToPetloveClub() {
      Gtm.sendCustomEvent(
        'click',
        'menu:servicos:botao:clube-petlove',
        'menu-lp'
      );
      this.close();
      if (this.$route.path !== '/') {
        this.$router.push({
          path: '/',
          query: persistNavigationQuery(this.$route.query)
        });
        setTimeout(() => this.$scrollTo('#discount-club'), 300);
      } else {
        this.$scrollTo('#discount-club');
      }
    },
    goToHire() {
      Gtm.sendCustomEvent('click', `menu:botao:contratar-agora`, 'menu-lp');
      this.$router.push(navigateToCheckout({ params: this.$route.query }));
    },
    handleSelectRegion() {
      this.$nuxt.$emit('show:modal', 'selectRegion');
    },
    showDiscountClub() {
      const isOwnPlansPartner = OwnPlansPartners.includes(this.promoCode);
      return !isOwnPlansPartner || (this.showBundleAddOn && isBundleRegions());
    },
    trackClick(eventName) {
      Gtm.sendCustomEvent('click', `${eventName}`, 'menu-lp');
      this.close();
    }
  }
};
