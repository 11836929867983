
import { mapGetters } from 'vuex';

export default {
  props: {
    moto: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      logoPorto: 'getLogoPorto',
      partner: 'getParceiro'
    }),
    hasLogoPartner() {
      return this.partner?.status_logo_partner && this.partner?.banner_img;
    },
    hasAnyUnion() {
      return this.logoPorto || this.hasLogoPartner;
    },
    unionPartner() {
      let unions = '';
      if (this.logoPorto) {
        unions = 'Porto';
      }
      if (this.hasLogoPartner) {
        if (this.logoPorto) {
          unions = unions + ' e ';
        }
        unions = unions + this.partner.social_name;
      }
      return unions;
    }
  }
};
