import Gtm from '@/utils/gtm';

export default ({ app }) => {
  app.router.afterEach((to, from) => {
    if (document) {
      const page = {
        title: document.title,
        location: document.location.href,
        prevUrl: `${document.location.origin}${from.fullPath}`
      };

      Gtm.sendVirtualPageViewEvent(page);
    }
  });
};
