export const OwnPlansPartners = [
  'banco-btg',
  'caasp',
  'aon',
  'rb-26',
  'banco-itau',
  'vivo',
  'banco-itau-uniclass',
  'banco-itau-personnalite'
];
