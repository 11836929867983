import AuthService from '@/services/auth';
export default ({ $axios }, inject) => {
  const API = $axios.create({
    headers: {
      common: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }
  });

  API.setBaseURL(process.env.DASH_API_URL);

  API.interceptors.request.use(
    (config) => {
      const authService = new AuthService();
      const headers = authService.getAuthHeaders();

      if (headers) {
        config.headers = headers;
        return config;
      }

      authService.logout();
      return false;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  API.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 401 || error?.response?.status === 404) {
        const authService = new AuthService();
        authService.logout();
        location.href = '/contrate/dados-pessoais?token=expired';
      }
      return Promise.reject(error);
    }
  );

  // Inject to context as $dashApi
  inject('dashApi', API);
};
