export function loadRdStationScript() {
  const rdStationScript =
    'https://d335luupugsy2.cloudfront.net/js/loader-scripts/4349ed3d-344a-422a-a86d-674eafd2e4d0-loader.js';

  if (document.querySelector('script[src=rdStationScript]')) {
    return;
  }

  const script = document.createElement('script');
  script.src = rdStationScript;
  script.async = true;

  script.onload = () => {};
  script.onerror = () =>
    console.error('Erro ao carregar script do RD Station.');

  document.body.appendChild(script);
}
