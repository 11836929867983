import { render, staticRenderFns } from "./ModalUtmAlert.vue?vue&type=template&id=6bbd8fb6"
import script from "./ModalUtmAlert.vue?vue&type=script&lang=js"
export * from "./ModalUtmAlert.vue?vue&type=script&lang=js"
import style0 from "./ModalUtmAlert.vue?vue&type=style&index=0&id=6bbd8fb6&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CaModal: require('/app/node_modules/@petlove/caramelo-vue-components/src/Modal/index.vue').default})
