import Vue from 'vue';
import VueCookie from 'vue-cookie';

import {
  getPaymentMethodName,
  PURCHASE,
  HIRING,
  YEARLY,
  MONTHLY,
  PLANS,
  USERDATA
} from '@/models/GtmEvents';
import AuthService from '@/services/auth';
import Utils from '@/utils/utils';

const authService = new AuthService();

export default {
  async sendEvent(
    ctx,
    event,
    userData = null,
    { subscriptionId = null, bundleInfo = null } = {}
  ) {
    if (!process.client || !Array.isArray(window?.dataLayer)) return;

    const isBilledAnnually = ctx.$store.state.payment.payment?.annually;
    const order = ctx.$store.state.payment?.orderValues;
    const payment_method = getPaymentMethodName(
      ctx.$store.state.payment?.payment?.method,
      isBilledAnnually
    );
    const source = ctx.$store.state?.source;
    const user = ctx.$store.state?.user;

    let eventData = {
      event
    };

    let userPets = null;
    if (user?.pets?.length) {
      userPets = user.pets.map((pet) => {
        return {
          name: pet.name,
          plan_name: pet.plan_name,
          price: isBilledAnnually
            ? parseFloat(pet.fullPrice) * 12
            : parseFloat(pet.fullPrice)
        };
      });
    }

    if (userData || userPets) {
      eventData = {
        ...eventData,
        userData: {
          name: userData?.name,
          email: userData?.email,
          telephone:
            userData?.ddd && userData?.telephone
              ? userData.ddd + userData.telephone
              : false,
          pets: userPets
        }
      };
    }

    if (userPets && order) {
      const revenue_liq = isBilledAnnually
        ? order?.singlePayment
        : order?.installments[0];
      const revenue_gross = userPets?.reduce(
        (partialSum, pet) => partialSum + pet.price,
        0
      );
      const total_discount = revenue_gross - revenue_liq;

      eventData = {
        ...eventData,
        revenue_liq,
        revenue_gross,
        total_discount,
        payment_method,
        subscription_id: subscriptionId,
        source
      };
    }

    if (bundleInfo) {
      eventData = {
        ...eventData,
        clube_incluido: bundleInfo
      };
    }
    const email = userData?.email ?? authService.getUser().st_email;
    if (email) {
      eventData.email_hash = await this.getHashUserEmail(email);
    }
    window?.dataLayer?.push(eventData);
  },
  sendCustomEvent(category, action, label) {
    if (!process.client || !Array.isArray(window?.dataLayer)) return;

    window?.dataLayer?.push({
      event: 'custom_event',
      event_category: category,
      event_action: action,
      event_label: label,
      event_noninteraction: 0,
      event_value: 0,
      value: 0
    });
  },
  sendItemListEvent(plans, event, itemListName = null, value = null) {
    if (!process.client || !Array.isArray(window?.dataLayer)) return;

    window?.dataLayer?.push({ ecommerce: null });
    window?.dataLayer?.push({
      event,
      ecommerce: {
        value,
        currency: 'BRL',
        item_list_name: itemListName,
        items: plans
      }
    });
  },
  sendLPHomeEvent(ownerAction, action) {
    const normalizeAction = Utils.removeEspecialCharacters(action);
    const eventAction = normalizeAction.toLowerCase().replace(/ /g, '-');

    this.sendCustomEvent('click', `${ownerAction}:${eventAction}`, 'lp-saude');
  },
  sendDefaultUserData(ctx) {
    if (!process.client || !Array.isArray(window?.dataLayer)) return;

    const user = ctx.$store.state?.user?.customerData;
    const region = ctx.$store.state?.guestRegion;
    const public_id = VueCookie.get('public_id');

    let first_name = '';
    let last_name = '';
    let phone = '';

    const nameParts = user?.name?.split(' ');
    if (nameParts?.length > 0) {
      first_name = nameParts[0];
      if (nameParts?.length > 2) {
        nameParts.shift();

        for (let i = 0; i < nameParts.length; i++) {
          last_name += nameParts[i];
          if (i < nameParts.length - 1) {
            last_name += ' ';
          }
        }
      }
    }

    if (user?.ddd && user?.telephone) {
      phone = '0' + user.ddd + user.telephone;
    }

    const email = user?.email;
    const zip_code = user?.zipcode;
    const state = user?.addressState ? user?.addressState : region?.state_slug;
    const country = 'BR';
    const city = user?.addressCityName ? user?.addressCityName : region?.name;

    const dataEvent = {
      event: USERDATA,
      public_id,
      first_name,
      last_name,
      phone,
      email,
      zip_code,
      country,
      state,
      city
    };
    window?.dataLayer?.push(dataEvent);
  },
  async sendDefaultPurchase(ctx, isLogged, subscription) {
    if (!process.client || !Array.isArray(window?.dataLayer)) return;

    const isBilledAnnually = ctx.$store.state.payment.payment?.annually;
    const orderValues = ctx.$store.state.payment?.orderValues;
    const payment_method = getPaymentMethodName(
      ctx.$store.state.payment?.payment?.method,
      isBilledAnnually
    );
    const coupon = ctx.$store.state?.user.coupon?.code ?? '';
    const user = ctx.$store.state?.user;
    const customerData = user?.customerData;
    const email = user?.email ?? authService.getUser().email;
    const hashUserEmail = await this.getHashUserEmail(email);

    const userPets = this.getUserPurchaseUserPets(user.pets, isBilledAnnually);
    const { revenue_liq, revenue_gross, total_discount } =
      this.getPurchaseValues(
        ctx,
        isBilledAnnually,
        orderValues,
        userPets,
        ctx.$store.state.isDiscountClub
      );

    const dataEvent = {
      event: PURCHASE,
      ecommerce: {
        purchase: {
          order: {
            item_total: revenue_gross
          },
          actionField: {
            id: subscription,
            revenue: revenue_liq,
            discount: total_discount,
            coupon: coupon ?? '',
            isFirstBuy: !isLogged,
            dimension38: payment_method,
            dimension40: HIRING,
            dimension44: isBilledAnnually ? YEARLY : MONTHLY,
            state: customerData?.addressState,
            city: customerData?.addressCityName,
            business_unit: 'saude-contratacao'
          },
          products: userPets
        }
      },
      email_hash: hashUserEmail
    };
    window?.dataLayer?.push(dataEvent);
  },
  getUserPurchaseUserPets(pets, isBilledAnnually) {
    let userPets = null;
    if (pets?.length) {
      userPets = pets.map((pet) => {
        return {
          name: pet.plan_name,
          id: pet.plan_id,
          category: PLANS,
          quantity: 1,
          price: isBilledAnnually
            ? parseFloat(pet.fullPrice) * 12
            : parseFloat(pet.fullPrice)
        };
      });
    }
    return userPets;
  },
  getPurchaseValues(
    ctx,
    isBilledAnnually,
    orderValues,
    userPets,
    isDiscountClub
  ) {
    let revenue_liq = isBilledAnnually
      ? orderValues?.singlePayment
      : orderValues?.installments[0];
    let revenue_gross = userPets?.reduce(
      (partialSum, pet) => partialSum + pet.price,
      0
    );
    if (isDiscountClub) {
      const discountClubPrice =
        ctx.$store.getters['additionalServices/getDiscountClubPrice'];
      const addonValue = isBilledAnnually
        ? discountClubPrice * 12
        : discountClubPrice;
      revenue_liq = revenue_liq + addonValue;
      revenue_gross = revenue_gross + addonValue;
    }

    let total_discount = revenue_gross - revenue_liq;
    total_discount = Math.round(total_discount * 100) / 100;

    return {
      revenue_liq,
      revenue_gross,
      total_discount
    };
  },
  async getHashUserEmail(email) {
    if (!email) return;

    return await Utils.generateSHA256Hash(email);
  },
  sendDynamicYieldEvent(variant, campaignName) {
    if (!variant) {
      return;
    }

    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'DY Variation Impression',
        campaignName,
        variationName: variant
      });
    }
  },
  sendVirtualPageViewEvent(page) {
    try {
      if (process.client) {
        if (!Array.isArray(window?.dataLayer)) {
          window.dataLayer = window.dataLayer || [];
        }
        window.dataLayer?.push({
          event: 'virtual_page_view',
          page_title: page.title,
          page_location: page.location,
          page_referrer: page.prevUrl
        });
      }
    } catch (error) {
      Vue.prototype.$honeybadger.notify(error);
    }
  }
};
