import Gtm from './gtm';

import AuthService from '../services/auth';

import Vue from 'vue';
import VueCookie from 'vue-cookie';

Vue.use(VueCookie);

const authService = new AuthService();

export default {
  watchNotifyEvent(ctx, userData = null) {
    ctx.$off('notify-event');
    ctx.$on('notify-event', (event, bundleInfo, callback, pets = null) => {
      this.sendEvents(ctx, event, userData, bundleInfo, callback, pets);
    });
  },
  async sendEvents(
    ctx,
    event,
    userData = null,
    bundleInfo = null,
    callback = null,
    pets = null
  ) {
    Gtm.sendEvent(ctx, event, userData, { bundleInfo });
    if (typeof event === 'string' || event instanceof String) {
      if (!ctx?.$store?.getters?.getIsItau) {
        await this.sendToTrack(ctx, event, userData, pets);
        if (callback) {
          callback();
        }
      }
    }
  },
  sendToTrack(ctx, event, userData = null, pets = null) {
    return new Promise((resolve, reject) => {
      try {
        resolve(this.dispatch(ctx, event, userData, pets));
      } catch (err) {
        reject(err);
      }
    }).catch((error) => {
      this.$nuxt?.$honeybadger?.notify(
        `Envia dados para o Track/RD - ${error}`,
        {
          tags: ['RDStation'],
          params: {
            traffic_source: VueCookie.get('__trf.src') || null,
            utm_source: sessionStorage.getItem('utm_source') || null,
            utm_medium: sessionStorage.getItem('utm_medium') || null,
            utm_campaign: sessionStorage.getItem('utm_campaign') || null
          }
        }
      );
    });
  },
  async dispatch(ctx, event, userData = null, pets = null) {
    let userAuthenticate;

    if (authService.isLogged()) {
      userAuthenticate = await authService.setUserInfo(ctx);
    }

    const payload = {
      nfid: this.getSessionId(),
      type: event,
      email: userAuthenticate ? userAuthenticate.st_email : userData?.email,
      origin: process.env.SITE_URL,

      ...this.getDeviceProperties(),
      ...this.getRouterProperties(ctx),
      ...this.getDesiredCookies(ctx),
      ...this.getAdditionalPayload(ctx, event, userAuthenticate, pets),
      ...this.getAdditionalPayloadLPBenefits(ctx),
      ...this.getAdditionalPayloadReceiveCall(ctx)
    };

    return ctx.$track.post('/track', payload);
  },
  getSessionId() {
    let sessionId = VueCookie.get('nfid');

    if (!sessionId) {
      sessionId = [
        new Date().getTime(),
        Math.random().toString(36).substring(2)
      ].join('-');
      VueCookie.set('nfid', sessionId, {
        expires: Infinity,
        secure: true
      });
    }

    return sessionId;
  },
  getDeviceProperties() {
    return {
      device_cookie_enabled: window.navigator.cookieEnabled,
      device_language: window.navigator.language,
      device_max_touch_points: window.navigator.maxTouchPoints,
      device_user_agent: window.navigator.userAgent,
      device_vendor: window.navigator.vendor,
      width: window.screen.width,
      height: window.screen.height
    };
  },
  getRouterProperties(ctx) {
    const router = ctx.$nuxt.$route;

    if (!router) {
      return {};
    }

    return {
      path: router.path == '/' ? '/home' : router.path,
      utm_campaign: router.query.utm_campaign
        ? router.query.utm_campaign
        : null,
      utm_medium: router.query.utm_medium ? router.query.utm_medium : null,
      utm_source:
        router.query.utm_source || sessionStorage.getItem('utm_source') || null,
      utm_content: router.query.utm_content ? router.query.utm_content : null,
      utm_term: router.query.utm_term ? router.query.utm_term : null,
      gclid: router.query.gclid ? router.query.gclid : null,
      partner:
        router.query.utm_source ||
        ctx?.$store?.getters?.getPartnerCode ||
        sessionStorage.getItem('utm_source') ||
        null,
      promocao: router.query.promocao ? router.query.promocao : null
    };
  },
  getDesiredCookies(ctx) {
    const rdTrack = this.getRdCookie('rdtrk');
    const trafficSource = ctx?.$store?.getters?.getSource
      ? ctx.$store.getters.getSource
      : VueCookie.get('__trf.src');
    const clientId = VueCookie.get('_ga');
    if (clientId) clientId.substring(6);

    return {
      rdtrk: rdTrack,
      trfsrc: trafficSource,
      client_id: clientId
    };
  },
  getAdditionalPayload(ctx, event, userAuthenticate, pets = null) {
    const payload = {};
    if (ctx.$store.state.user.pets) {
      const selectedPaymentMethod =
        ctx.$store.state.payment?.paymentMethods.find(
          (method) => method.code === ctx.$store.state.payment.payment.method
        );

      if (event === 'avancou_contratar' && pets) {
        payload.pets = pets.map((p) => {
          return {
            id: p.id,
            name: p.name,
            plan_name: p.plan_name,
            payment_method: selectedPaymentMethod?.name,
            payment_frequency: 'months',
            payment_installment:
              ctx.$store.state.payment?.payment.annually === 1 ? 12 : 1
          };
        });
      } else {
        payload.pets = ctx.$store.state.user.pets.map((p) => {
          const pet = {
            name: p.name,
            plan_name: p.plan_name
          };

          if (event === 'avancou_contratar') {
            pet.payment_method = selectedPaymentMethod?.name;
            pet.payment_frequency = 'months';
            pet.payment_installment =
              ctx.$store.state.payment?.payment.annually === 1 ? 12 : 1;
          }
          return pet;
        });
      }

      if (authService.isLogged()) {
        payload.customerData = {
          name: userAuthenticate.st_name,
          telephone: userAuthenticate.telephone,
          regionId: ctx.$store.state.guestRegion.id
        };
      } else if (ctx.$store.state.user.customerData) {
        payload.customerData = ctx.$store.state.user.customerData;
        payload.customerData.regionId = ctx.$store.state.guestRegion.id;
      }
    }
    return { data: payload };
  },
  getRdCookie(cookieName) {
    try {
      return JSON.parse(VueCookie.get(cookieName));
    } catch (error) {
      this.$nuxt?.$honeybadger?.notify(
        error,
        `Erro ao recuperar o cookie para o RD.`,
        {
          tags: ['RDStation'],
          params: {
            cookieName
          }
        }
      );
    }
  },
  getAdditionalPayloadLPBenefits(ctx) {
    if (ctx.$store.state.requestBenefitsLP.companyEmail) {
      const payload = {};

      payload.customerData = {
        regionId: ctx.$store.state.guestRegion.id,
        telephone: ctx.$store.state.requestBenefitsLP.number,
        ddd: ctx.$store.state.requestBenefitsLP.ddd,
        name: ctx.$store.state.requestBenefitsLP.name
      };

      return {
        company: ctx.$store.state.requestBenefitsLP.companyName,
        sector_company: ctx.$store.state.requestBenefitsLP.companySector,
        company_size: ctx.$store.state.requestBenefitsLP.companySize,
        email: ctx.$store.state.requestBenefitsLP.companyEmail,
        data: payload
      };
    }
  },
  getAdditionalPayloadReceiveCall(ctx) {
    if (ctx.$store.state.receiveCall.name) {
      const payload = {};

      payload.customerData = {
        regionId: ctx.$store.state.guestRegion.id,
        telephone: ctx.$store.state.receiveCall.telephone,
        ddd: ctx.$store.state.receiveCall.ddd,
        name: ctx.$store.state.receiveCall.name
      };

      return { email: ctx.$store.state.receiveCall.email, data: payload };
    }
  }
};
