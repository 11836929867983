export default class UserService {
  getUserAddress(ctx) {
    return ctx.$dashApi.get('/address').then((res) => res.data);
  }

  getUserPaymentCards(ctx) {
    return ctx.$dashApi.get('/user/vindi/cards').then((res) => {
      return res.data;
    });
  }

  getUserBillStatus(ctx, { id, externalToken }) {
    const options = {
      headers: {
        Authorization: 'Bearer ' + externalToken
      }
    };
    return ctx.$dashApi
      .get(`/user/vindi/subscriptions/${id}/bill-status`, options)
      .then((res) => res.data);
  }

  getUserRegion(ctx) {
    return ctx.$dashApi.get('/user/region').then((res) => res.data);
  }

  getUserInfo(ctx) {
    return ctx.$dashApi.get('/user/info-basic').then((res) => res.data);
  }

  getUserPets(ctx) {
    return ctx.$dashApi.get('/pet').then((res) => res.data);
  }
}
