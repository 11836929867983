
import { mapGetters, mapMutations, mapActions } from 'vuex';

import Gtm from '@/utils/gtm';
import strings from '@/utils/strings';

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    regions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      inputQuery: '',
      selectedRegion: '',
      lastSelectedRegion: '',
      contractURL: false
    };
  },
  computed: {
    ...mapGetters({
      guestRegion: 'getGuestRegion',
      getParceiro: 'getParceiro',
      promoCode: 'getPromoCode'
    }),
    filteredRegions() {
      let filtered = this.regions;

      if (this.inputQuery.length) {
        const sanitizedQuery = strings.sanitize(this.inputQuery);
        filtered = filtered.filter((region) => {
          const sanitizedRegion = strings.sanitize(region.name);
          return sanitizedRegion?.includes(sanitizedQuery);
        });

        if (this.getParceiro?.isItau && filtered.length === 0) {
          const anotherRegion = this.regions.find(
            (region) => region.id === parseInt(process.env.OTHER_REGIONS_ID)
          );
          if (anotherRegion) {
            filtered.push(anotherRegion);
          }
        }
      }

      if (!this.getParceiro?.isItau) {
        filtered = filtered.filter(
          (region) => region.id !== parseInt(process.env.OTHER_REGIONS_ID)
        );
      }

      return filtered;
    }
  },
  watch: {
    guestRegion: {
      handler(value) {
        this.selectedRegion = value;
      },
      immediate: true
    },
    show() {
      this.$nextTick(() => {
        this.lastSelectedRegion = this.guestRegion;
        const contractPath = this.$route.fullPath.includes('/contrate');
        if (contractPath) {
          this.contractURL = true;
        }
        document.getElementById('region_search')?.focus();

        const ownerAction = this.$route.path;
        Gtm.sendCustomEvent(
          'modal',
          `${ownerAction}:show-region-modal`,
          'lp-saude'
        );
      });
    }
  },
  methods: {
    ...mapMutations({
      setGuestRegion: 'set_guestRegion',
      setPets: 'set_pets',
      setPlans: 'add_plans'
    }),

    ...mapActions(['loadPlans']),

    setRegion(region) {
      this.selectedRegion = region;
      this.confirmRegion();
    },
    onClose() {
      this.$emit('close');
    },
    async confirmRegion() {
      if (this.selectedRegion) {
        this.selectedRegion.firstAccess = false;
        this.selectedRegion.selectedByUser = true;
        await this.setGuestRegion(this.selectedRegion);
        !this.promoCode && this.loadPlans();
        if (
          this.contractURL &&
          this.lastSelectedRegion !== this.selectedRegion
        ) {
          this.$router.push({
            query: { ...this.$route.query, planName: undefined }
          });
          this.setPlans([]);
          this.setPets([]);
        }
        this.onClose();
      }
    }
  }
};
