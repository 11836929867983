
import { Tooltip } from 'buefy';
import Vue from 'vue';

import QuestionCircle from '@/assets/images/icons/question-circle.svg';

Vue.use(Tooltip);

export default {
  name: 'Tooltip',
  components: {
    QuestionCircle
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      default: 'is-top'
    },
    size: {
      type: String,
      default: 'is-large'
    }
  },
  data() {
    return {
      isActive: false
    };
  }
};
