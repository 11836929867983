import Vue from 'vue';

export default ({ $axios }) => {
  const notifiedErrors = new Set();

  $axios.onError((error) => {
    const errorKey = `${error?.message}-${error?.stack?.slice(0, 100)}`;

    if (error?.isHandled || notifiedErrors.has(errorKey)) {
      return Promise.reject(error);
    }

    error.isHandled = true;
    notifiedErrors.add(errorKey);
    const statusCode = error?.response?.status;

    if (statusCode >= 400 && statusCode < 500) {
      return Promise.reject(error);
    }

    if (error?.message === 'Network Error' || statusCode >= 500) {
      Vue.prototype.$honeybadger.notify(error, {
        context: {
          error: {
            name: error?.name,
            message: error?.message,
            type: error?.constructor?.name,
            stackTrace: error?.stack,
            statusCode: error?.response?.status
          },
          request: error?.config && {
            url: error?.config?.url,
            method: error?.config?.method,
            baseURL: error?.config?.baseURL
          },
          vue: {
            component: window?.$nuxt?.$route?.name || 'Unknown',
            route: window?.$nuxt?.$route?.path,
            routeParams: window?.$nuxt?.$route?.params,
            routeQuery: window?.$nuxt?.$route?.query
          },
          environment: {
            timestamp: new Date().toISOString(),
            userAgent: window?.navigator?.userAgent,
            url: window?.location?.href,
            previousUrl: document?.referrer
          }
        },
        name:
          error?.message === 'Network Error' ? 'NetworkError' : 'AxiosError',
        fingerprint: `${error?.config?.method}-${error?.config?.url}-${statusCode}`,
        tags: ['critical']
      });
    }

    return Promise.reject(error);
  });
};
