
import { mapMutations } from 'vuex';

import Gtm from '@/utils/gtm';
export default {
  name: 'ModalBuyTerms',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    isCheckout: {
      type: Boolean,
      default: false
    },
    hasFooter: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      contract: null
    };
  },
  mounted() {
    this.getContract();
  },
  methods: {
    ...mapMutations({
      setAcceptTermsOfUse: 'set_acceptTermsOfUse'
    }),
    closeModal() {
      if (this.isCheckout) {
        Gtm.sendCustomEvent(
          'click',
          'modal-termos-uso:icone:fechar',
          'checkout_contratacao'
        );
      }
      this.$emit('close');
    },
    getContract() {
      this.$axios.get('/termos-de-uso').then((res) => {
        this.contract = res.data.text;
      });
    },
    confirmTerms() {
      this.setAcceptTermsOfUse(true);
      this.$emit('close');
      Gtm.sendCustomEvent(
        'click',
        'modal-termos-uso:botao:confirmar-leitura',
        'checkout_contratacao'
      );
    },
    scrollToTop() {
      const modalElement = this.$el.querySelector('.modal__slot');
      if (modalElement) {
        modalElement.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    }
  }
};
