export default {
  async getAdditionalServices(ctx, type) {
    try {
      const response = await ctx.$axios.get('/addons', {
        params: { type }
      });

      return response.data;
    } catch (error) {
      ctx.$nuxt?.$honeybadger?.notify(error);
      throw error;
    }
  }
};
