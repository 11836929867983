import accreditedNetworkService from '@/services/accreditedNetworkService';

export const state = () => ({
  selectedRegion: {},
  states: [],
  cities: [],
  activeCities: [],
  regions: [],
  filterTags: [],
  partnerSelected: undefined,
  routeMode: false,
  userLocation: undefined,
  addressAutocomplete: {},
  scrollLastPosition: 0
});

export const getters = {
  getSelectedRegion: (state) => state.selectedRegion,
  getStates: (state) => state.states,
  getCities: (state) => state.cities,
  getActiveCities: (state) => state.activeCities,
  getRegions: (state) => state.regions,
  getFilterTags: (state) => state.filterTags,
  getPartnerSelected: (state) => state.partnerSelected,
  getRouteMode: (state) => state.routeMode,
  getUserLocation: (state) => state.userLocation,
  getAddressAutocomplete: (state) => state.addressAutocomplete,
  getScrollLastPosition: (state) => state.scrollLastPosition
};

export const mutations = {
  set_selectedRegion(state, value) {
    state.selectedRegion = value;
  },
  set_states(state, value) {
    state.states = value;
  },
  set_cities(state, value) {
    state.cities = value;
  },
  set_activeCities(state, value) {
    state.activeCities = value;
  },
  set_regions(state, value) {
    state.regions = value;
  },
  set_filterTags(state, value) {
    state.filterTags = value;
  },
  set_partnerSelected(state, value) {
    state.partnerSelected = value;
  },
  set_routeMode(state, value) {
    state.routeMode = value;
  },
  set_userLocation(state, value) {
    state.userLocation = value;
  },
  set_addressAutocomplete(state, value) {
    state.addressAutocomplete = value;
  },
  set_scrollLastPosition(state, value) {
    state.scrollLastPosition = value;
  }
};

export const strict = false;

export const actions = {
  async getCityFromPath({ getters, commit, dispatch }, path) {
    if (!path) return;

    const params = path?.split('/');
    const stateSlug = params[0];
    const selectedRegion = getters.getSelectedRegion;

    !selectedRegion.isAddressAutocomplete && (await dispatch('loadStates'));
    const states = await getters.getStates;

    const isStateCityKindOfPath = states.some(
      (state) => state.uf === stateSlug.toUpperCase()
    );

    const isStateOnlyKindOfPath =
      isStateCityKindOfPath &&
      stateSlug.length === 2 &&
      (params.length === 1 || (params.length === 2 && !params[1]));

    let cityOrRegion;
    if (isStateOnlyKindOfPath) {
      const stateChanged =
        getters.getSelectedRegion.state_slug !== stateSlug.toUpperCase();

      if (!stateChanged) return;

      const stateSelected = states.find(
        (state) => state.uf.toLowerCase() === stateSlug.toLowerCase()
      );

      if (!stateSelected) return 'error';

      const selected = {
        ...stateSelected,
        state_slug: stateSlug,
        region_id: stateSelected.id
      };

      await commit('set_selectedRegion', selected);
      await dispatch('loadCities', stateSlug);
      return;
    } else if (isStateCityKindOfPath && !isStateOnlyKindOfPath) {
      const citySlug = params[1];

      const stateChanged =
        getters.getSelectedRegion.state_slug !== stateSlug.toUpperCase();
      const cityChanged =
        getters.getSelectedRegion.city_slug !== citySlug.toUpperCase();

      if (!stateChanged && !cityChanged) return;

      await dispatch('loadCities', stateSlug);
      const cities = await accreditedNetworkService.getCities(this, stateSlug, {
        isAll: true
      });

      cityOrRegion = cities.find((city) => city.slug === citySlug);

      if (!cityOrRegion) return 'error';

      if (params[2]) {
        const partnerSlug = params[2];
        const partner = await accreditedNetworkService
          .getPartner(this, partnerSlug)
          .catch((error) => {
            console.error(error);
            if (error.response.status === 404) {
              return 'error';
            }
          });

        if (partner === 'error') return 'error';
        await commit('set_partnerSelected', partner);
      }
    } else {
      await dispatch('loadRegions');
      const regions = await getters.getRegions;

      const regionSlug = params[0];
      const partnerSlug = params[1];
      cityOrRegion = regions.find((region) => region.slug === regionSlug);

      if (!cityOrRegion) return 'error';

      await dispatch('getCityFromStartRegion', cityOrRegion);
      const partner = await accreditedNetworkService
        .getPartner(this, partnerSlug)
        .catch((error) => {
          console.error(error);
          if (error.response.status === 404) {
            return 'error';
          }
        });
      if (partner === 'error') return 'error';
      await commit('set_partnerSelected', partner);

      return;
    }

    const coordinates = {
      lat: cityOrRegion?.lat
        ? cityOrRegion.lat
        : cityOrRegion?.map_center_latitude || 0,
      lng: cityOrRegion?.lng
        ? cityOrRegion.lng
        : cityOrRegion?.map_center_longitude || 0
    };

    const selected = {
      ...cityOrRegion,
      ...coordinates,
      state_slug: stateSlug,
      city_slug: cityOrRegion?.slug,
      city_name: cityOrRegion?.name
    };

    const autocompleteSelected = {
      ...getters.getAddressAutocomplete,
      region_id: selected.region_id
    };
    const userAddress = getters.getAddressAutocomplete?.place_id
      ? autocompleteSelected
      : selected;
    commit('set_selectedRegion', userAddress);
  },
  async getCityFromStartRegion({ getters, commit, dispatch }, startRegion) {
    if (!startRegion) return;
    const otherRegionsId = parseInt(process.env.OTHER_REGIONS_ID);

    const uf =
      startRegion.id === otherRegionsId
        ? 'sp'
        : startRegion?.state_slug?.toLowerCase();
    if (!uf) return;

    await dispatch('loadCities', uf);

    const city = getters.getCities.find((city) => {
      return startRegion.id === otherRegionsId
        ? 'sao-paulo-e-regiao'.includes(city.slug)
        : startRegion.slug.includes(city.slug);
    });

    const region = {
      ...city,
      state_slug: uf,
      city_slug: city.slug,
      city_name: city.name
    };

    const userAddress = getters.getAddressAutocomplete?.place_id
      ? getters.getAddressAutocomplete
      : region;

    await commit('set_selectedRegion', userAddress);
  },
  loadStates({ commit, getters }) {
    if (getters.getStates?.length <= 0) {
      return accreditedNetworkService.getStates(this).then((data) => {
        commit('set_states', data);
      });
    }
  },
  loadCities({ commit, getters }, stateSlug) {
    if (
      getters.getCities?.length <= 0 ||
      getters.getSelectedRegion?.state_slug !== stateSlug
    ) {
      const utmSource = sessionStorage.getItem('utm_source');
      const params = utmSource?.includes('itau') ? { isAll: true } : {};
      return accreditedNetworkService
        .getCities(this, stateSlug, params)
        .then((data) => {
          commit('set_cities', data);

          const activeCities = data.filter(
            (city) => !!city.active && !!city.region_id
          );
          commit('set_activeCities', activeCities);
        });
    }
  },
  loadRegions({ commit, getters }) {
    if (getters.getRegions?.length <= 0) {
      return accreditedNetworkService.getRegions(this).then((data) => {
        commit('set_regions', data);
      });
    }
  }
};
