export const state = () => ({
  petNames: ''
});

export const getters = {
  getPetNames: (state) => {
    return state.petNames;
  }
};

export const mutations = {
  set_petNames(state, petNames) {
    state.petNames = petNames;
  }
};

export const actions = {};
