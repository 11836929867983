
import { mapGetters } from 'vuex';

import NavbarPhoneTop from '@/assets/images/navbar-phone-top.svg';
import { CONTACTS } from '@/models/Contacts';
import { PartnersSource } from '@/models/PurchaseSource';
import Gtm from '@/utils/gtm';
import { HeadHome } from '@/utils/heads';

export default {
  components: {
    NavbarPhoneTop
  },
  data() {
    return {
      CONTACTS,
      HeadHome
    };
  },
  computed: {
    ...mapGetters({
      isItau: 'getIsItau',
      guestRegion: 'getGuestRegion',
      memberFromBroker: 'getMemberFromBroker',
      partnerCode: 'getPartnerCode',
      partnerWhitelabelConfig: 'getPartnerWhitelabelConfig'
    }),
    isAccreditNetworkPage() {
      return this.$route?.fullPath.includes('rede-credenciada');
    },
    guestRegionName() {
      return this.guestRegion?.name || '';
    },
    shouldShowSalesInfo() {
      return (
        !PartnersSource.isPartnerOnHideInsideSalesInfoList(this.partnerCode) &&
        !this.isItau &&
        !this.partnerWhitelabelConfig.hideInsideSalesPhone
      );
    },
    showSalesInfo() {
      return (
        this.guestRegion &&
        this.guestRegion.st_telephone &&
        this.shouldShowSalesInfo &&
        !this.memberFromBroker
      );
    },
    normalizedPhone() {
      return this.guestRegion?.st_cellphone?.replace(/\D/g, '');
    }
  },
  methods: {
    sendCustomEvent(category, action, label) {
      Gtm.sendCustomEvent(category, action, label);
    }
  }
};
