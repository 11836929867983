/*
 * Constants
 */
const defaultHeader = {
  title: 'Plano de Saúde Pet: proteja seu cão ou gato agora! | Petlove',
  description:
    'Faça parte do plano de saúde pet para cães e gatos e tenha acesso a mais de 4.500 clínicas veterinárias e pet shops. Clique e confira os valores na sua região!'
};

const metaOG = [
  {
    hid: 'og-type',
    property: 'og:type',
    content: 'website'
  },
  {
    hid: 'og-title',
    property: 'og:title',
    content: defaultHeader.title
  },
  {
    hid: 'og-desc',
    property: 'og:description',
    content: defaultHeader.description
  },
  {
    hid: 'og-image',
    property: 'og:image',
    content: `${process.env.SITE_URL}/images/meta-image.png`
  },
  {
    hid: 'og-url',
    property: 'og:url',
    content: process.env.SITE_URL
  },
  {
    hid: 't-type',
    name: 'twitter:card',
    content: 'summary_large_image'
  }
];

const sharedMeta = [
  ...metaOG,
  {
    name: 'robots',
    content: 'all'
  }
];

/*
 * Functions
 */
const SimpleHead = ({ title = '', description = '' }) => {
  return {
    bodyAttrs: {},
    title,
    meta: [
      ...sharedMeta,
      {
        name: 'title',
        content: title
      },
      {
        name: 'description',
        content: description
      }
    ]
  };
};

export const HeadPromocoes = (title, description) => {
  return {
    bodyAttrs: {},
    title,
    meta: [
      ...sharedMeta,

      {
        name: 'title',
        content: title
      },
      {
        name: 'description',
        content: description
      }
    ]
  };
};

export const HeadIndicacoes = (title, description, url) => {
  return {
    bodyAttrs: {},
    titleTemplate: '%s',
    title,
    meta: [
      ...sharedMeta,
      {
        name: 'title',
        content: title
      },
      {
        name: 'description',
        content: description
      },
      { name: 'og:type', property: 'og:type', content: 'website' },
      { name: 'og:url', property: 'og:url', content: url },
      { name: 'og:title', property: 'og:title', content: title },
      {
        name: 'og:description',
        property: 'og:description',
        content: description
      },
      { name: 'og:site_name', property: 'og:site_name', content: title },
      {
        name: 'twitter:card',
        property: 'twitter:card',
        content: 'summary_large_image'
      },
      { name: 'twitter:url', property: 'twitter:url', content: url },
      { name: 'twitter:title', property: 'twitter:title', content: title },
      {
        name: 'twitter:description',
        property: 'twitter:description',
        content: description
      }
    ]
  };
};

export const HeadConfirmacao = (title, description) => {
  return {
    bodyAttrs: {},
    title,
    meta: [
      ...sharedMeta,
      {
        name: 'title',
        content: title
      },
      {
        name: 'description',
        content: description
      }
    ]
  };
};

/*
 * HEADS
 */
const HeadHome = (currentUrl) => ({
  bodyAttrs: {},
  title: defaultHeader.title,
  meta: [
    {
      name: 'title',
      content: defaultHeader.title
    },
    {
      name: 'description',
      content: defaultHeader.description
    },
    {
      name: 'google-site-verification',
      content: 'G9i-k84x-JhSBqb2gPRz7dzc9j4Dj33OB-vlAK2MjD4'
    }
  ],
  script: [
    {
      src: 'https://apis.google.com/js/api.js',
      async: true,
      defer: true,
      body: true
    }
  ],
  link: [
    {
      rel: 'canonical',
      href: `${process.env.SITE_URL}${currentUrl}`
    }
  ]
});

const HeadIndiqueGanhe = SimpleHead({
  title: 'Indique a Petlove aos seus amigos e ganhe descontos!',
  description:
    'Com o programa Petlove de um Amigo, você ganha descontos fixos, podendo até zerar sua mensalidade, confira!'
});

const HeadAvaliacaoRapida = SimpleHead({
  title: 'Avaliação de atendimento',
  description: 'Avaliação de atendimento.'
});

const HeadContrate = (currentUrl) => ({
  ...SimpleHead({
    title: 'Contratar Plano de Saúde Pet',
    description:
      'Veja as opções de planos de saúde pet da Petlove que melhor atendem as necessidades do seu cachorro ou gato. Acesse já!'
  }),
  link: [
    { rel: 'icon', type: 'image/x-icon', href: '/favicon-petlove.ico' },
    {
      rel: 'canonical',
      href: `${process.env.SITE_URL}${currentUrl}`
    }
  ],
  script: [
    {
      src: 'https://apis.google.com/js/api.js',
      async: true,
      defer: true,
      body: true
    }
  ]
});

const HeadPetTag = {
  ...SimpleHead({
    title: 'Localizador Pet',
    description:
      'Benefícios para TODOS os membros da família… inclusive os de quatro patas!'
  }),
  script: [
    {
      src: `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_CLOUD_KEY}&libraries=places&region=BR&language=pt-BR`,
      async: true,
      defer: true
    }
  ]
};

const HeadPriceSimulator = SimpleHead({
  title: 'Simulador de Valores',
  description: 'Simulador de Valores'
});

const HeadCoverageSimulator = SimpleHead({
  title: 'Simulador de Cobertura',
  description: 'Simulador de Cobertura'
});

const headAccreditation = SimpleHead({
  title: 'Plano Pet: faça parte da rede credenciada da Petlove!',
  description:
    'Faça parte da maior rede credenciada de plano de saúde pet do Brasil e atraia novos clientes para sua clínica veterinária ou pet shop!'
});

const HeadAccreditedNetwork = (metatags = {}, currentUrl) => {
  return {
    ...SimpleHead({
      title: metatags.title,
      description: metatags.description
    }),
    link: [
      {
        rel: 'canonical',
        href: `${process.env.SITE_URL}${currentUrl}`
      }
    ]
  };
};

const HeadAffiliate = SimpleHead({
  title: 'Seja um afiliado Petlove',
  description:
    'Conquiste uma renda extra indicando os planos de saúde para cães e gatos da Petlove. É sua oportunidade de trabalhar no horário que você escolher, por quanto tempo quiser e no local que preferir.'
});

const HeadTerms = (currentUrl) => ({
  ...SimpleHead({
    title: 'Termos e condições',
    description: defaultHeader.description
  }),
  link: [
    {
      rel: 'canonical',
      href: `${process.env.SITE_URL}${currentUrl}`
    }
  ]
});

const HeadPrivacyPolicy = SimpleHead({
  title: 'Política de Privacidade',
  description:
    'Política de Privacidade Petlove - Descreve como coletamos, utilizamos, processamos e divulgamos as informações pessoais de nossos clientes e colaboradores, respeitadas as informações necessárias para garantia da segurança das informações.'
});

const HeadCookiePolicy = SimpleHead({
  title: 'Política de Cookies',
  description:
    'Política de cookies Petlove - busca esclarecer a natureza e as finalidades destes dados coletados, garantindo segurança e transparência aos usuários.'
});

const HeadError = SimpleHead({
  title: 'Ocorreu um erro inesperado',
  description: defaultHeader.description
});

const HeadCompanies = (currentUrl) => ({
  ...SimpleHead({
    title: 'Plano de Saúde Pet para Empresas | Petlove',
    description:
      'Proteja os pets de seus colaboradores com o plano de saúde da Petlove! Ofereça esse benefício essencial com planos para cães e gatos. Confira agora!'
  }),
  link: [
    {
      rel: 'canonical',
      href: `${process.env.SITE_URL}${currentUrl}`
    }
  ]
});

const HeadTransparency = (currentUrl) => ({
  ...SimpleHead({
    title: 'Contratar Plano de Saúde Pet',
    description:
      'Veja as opções de planos de saúde pet da Petlove que melhor atendem as necessidades do seu cachorro ou gato. Acesse já!'
  }),
  link: [
    { rel: 'icon', type: 'image/x-icon', href: '/transparencia' },
    {
      rel: 'canonical',
      href: `${process.env.SITE_URL}${currentUrl}`
    }
  ]
});

export {
  HeadHome,
  HeadContrate,
  HeadIndiqueGanhe,
  HeadAvaliacaoRapida,
  headAccreditation,
  HeadAccreditedNetwork,
  HeadAffiliate,
  HeadPrivacyPolicy,
  HeadCookiePolicy,
  HeadCoverageSimulator,
  HeadPriceSimulator,
  HeadTerms,
  HeadError,
  HeadPetTag,
  HeadCompanies,
  HeadTransparency
};
