import UserService from '@/services/user';

const userService = new UserService();

export const state = () => ({
  userAuthenticatedAddress: []
});

export const getters = {
  getUserAuthenticatedAddress: (state) => state.userAuthenticatedAddress
};

export const mutations = {
  set_userAuthenticatedAddress(state, value) {
    state.userAuthenticatedAddress = value;
  }
};

export const actions = {
  async getUserAddress({ commit }) {
    await userService
      .getUserAddress(this)
      .then((data) => {
        commit('set_userAuthenticatedAddress', data);
      })
      .catch((error) => {
        console.error(error);
        this.$nuxt?.$honeybadger?.notify(
          `Fluxo de contratação (busca o endereço do usuário logado) - ${error}`,
          {
            tags: ['critical']
          }
        );
        commit('set_userAuthenticatedAddress', []);
      });
  }
};
