
import { mapGetters } from 'vuex';

import URLS from '@/models/Url';
import utils from '@/utils/utils';

export default {
  name: 'FooterDesktop',

  filters: {
    formatCNPJ: utils.formatCNPJ
  },

  data() {
    return {
      glossary: [
        {
          name: 'Conjuntivite',
          glossary: 'conjuntivite'
        },
        {
          name: 'Dermatite Atópica',
          glossary: 'dermatite-atopica'
        },
        {
          name: 'Pneumonia',
          glossary: 'pneumonia'
        },
        {
          name: 'Gastroenterite',
          glossary: 'gastroenterite'
        },
        {
          name: 'Candidíase',
          glossary: 'candidiase'
        },
        {
          name: 'Ver todas as doenças',
          glossary: ''
        }
      ],
      breeds: [
        {
          name: 'Shih Tzu',
          breed: 'shih-tzu/r'
        },
        {
          name: 'Husky Siberiano',
          breed: 'husky-siberiano/r'
        },
        {
          name: 'Pinscher',
          breed: 'pinscher/r'
        },
        {
          name: 'Pitbull',
          breed: 'pitbull/r'
        },
        {
          name: 'Rottweiler',
          breed: 'rottweiler/r'
        },
        {
          name: 'Ver todas as raças',
          breed: 'racas'
        }
      ]
    };
  },

  computed: {
    ...mapGetters({
      isItau: 'getIsItau',
      logoPorto: 'getLogoPorto',
      getPartner: 'getParceiro',
      regions: 'getRegions'
    }),

    primaryCallCenterPhone() {
      return this.getPartner?.primarySupportPhone || '4004 4444';
    },

    secondaryCallCenterPhone() {
      return this.getPartner?.secondarySupportPhone || '0800 727 4444';
    },

    isNewLP() {
      return this.$route?.query?.newLP === 'true';
    },

    ecommerceUrl() {
      return URLS().ECOM_URL;
    }
  },

  methods: {
    goToRegion(slug) {
      const slugRegion = slug.toLowerCase();
      this.$router.push(`/rede-credenciada/${slugRegion}`);
    },

    goToGlossary(glossary) {
      const url = `${this.ecommerceUrl}conteudo/saude/doencas/${glossary}`;
      window.open(url, '_blank');
    },

    goToBreeds(breeds) {
      const url = `${this.ecommerceUrl}${breeds}`;
      window.open(url, '_blank');
    }
  }
};
