import { render, staticRenderFns } from "./SnackBar.vue?vue&type=template&id=760f8765"
import script from "./SnackBar.vue?vue&type=script&lang=js"
export * from "./SnackBar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CaSnackbar: require('/app/node_modules/@petlove/caramelo-vue-components/src/Snackbar/index.vue').default})
