const getStates = (ctx) => {
  return ctx.$axios.$get('/location/states').then((res) => res.data);
};

const getCities = (ctx, stateSlug, params) => {
  return ctx.$axios
    .$get(`/location/states/${stateSlug}/cities`, { params })
    .then((res) => res.data);
};

const getRegions = (ctx) => {
  return ctx.$axios.$get('/regions');
};

const getPartners = (ctx, params) => {
  return ctx.$axios.$get('partners', { params });
};

const getPins = (ctx, params) => {
  return ctx.$axios.$get('partners/pins', { params });
};

const getPartner = (ctx, partnerSlug, params) => {
  return ctx.$axios.$get(`partners/${partnerSlug}`, { params });
};

const getCategories = (ctx, regionId) => {
  return ctx.$axios.$get(`/partners/categories?region=${regionId}`);
};

const getProcedures = (ctx, regionId) => {
  return ctx.$axios.$get(`/procedures?region=${regionId}`);
};

const getPartnerNames = (ctx, regionId) => {
  return ctx.$axios.$get(`partners/names?region=${regionId}`);
};
export default {
  getStates,
  getCities,
  getRegions,
  getPartners,
  getPins,
  getPartner,
  getCategories,
  getProcedures,
  getPartnerNames
};
