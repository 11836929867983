import { render, staticRenderFns } from "./FooterDesktop.vue?vue&type=template&id=7b45d537&scoped=true"
import script from "./FooterDesktop.vue?vue&type=script&lang=js"
export * from "./FooterDesktop.vue?vue&type=script&lang=js"
import style0 from "./FooterDesktop.vue?vue&type=style&index=0&id=7b45d537&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b45d537",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UnionLogos: require('/app/components/footer/UnionLogos.vue').default,CaCardAccordion: require('/app/node_modules/@petlove/caramelo-vue-components/src/CardAccordion/index.vue').default,CaDivisor: require('/app/node_modules/@petlove/caramelo-vue-components/src/Divisor/index.vue').default,CaLink: require('/app/node_modules/@petlove/caramelo-vue-components/src/Link/index.vue').default,CompanyLegal: require('/app/components/footer/CompanyLegal.vue').default})
