import { render, staticRenderFns } from "./ModalBuyTerms.vue?vue&type=template&id=6718d2da&scoped=true"
import script from "./ModalBuyTerms.vue?vue&type=script&lang=js"
export * from "./ModalBuyTerms.vue?vue&type=script&lang=js"
import style0 from "./ModalBuyTerms.vue?vue&type=style&index=0&id=6718d2da&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6718d2da",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CaIconButton: require('/app/node_modules/@petlove/caramelo-vue-components/src/IconButton/index.vue').default,CaButton: require('/app/node_modules/@petlove/caramelo-vue-components/src/Button/index.vue').default,CaModal: require('/app/node_modules/@petlove/caramelo-vue-components/src/Modal/index.vue').default})
