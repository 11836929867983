const FALLBACK_URL = {
  ORIGIN_URL: `https://plano-de-saude.petlove.com.br/#/login?origin=https://saude.petlove.com.br/contrate`,
  DASH_URL: 'https://plano-de-saude.petlove.com.br',
  SITE_URL: 'https://saude.petlove.com.br',
  ECOM_URL: 'https://www.petlove.com.br/',
  PARTNERS_URL: 'https://www.petlove.com.br',
  API_URL: 'https://health-api.petlove.com.br/api/',
  TRACK_URL: 'https://health-track.petlove.com.br/public/',
  AUTH_URL: 'https://auth.petlove.com.br/saude',
  NUXT_ENV_SSO_URL: 'https://auth.petlove.com.br'
};

const getFallback = (url) => {
  return FALLBACK_URL[url];
};

const URLS = () => {
  // About envs -> https://v2.nuxt.com/docs/configuration-glossary/configuration-env/
  const DASH_URL = process.env.DASH_URL
    ? process.env.DASH_URL
    : getFallback('DASH_URL');
  const SITE_URL = process.env.SITE_URL
    ? process.env.SITE_URL
    : getFallback('SITE_URL');
  const ECOM_URL = process.env.ECOM_URL
    ? process.env.ECOM_URL
    : getFallback('ECOM_URL');
  const PARTNERS_URL = process.env.PARTNERS_URL
    ? process.env.PARTNERS_URL
    : getFallback('PARTNERS_URL');
  const API_URL = process.env.API_URL
    ? process.env.API_URL
    : getFallback('API_URL');
  const TRACK_URL = process.env.TRACK_URL
    ? process.env.TRACK_URL
    : getFallback('TRACK_URL');
  const AUTH_URL = process.env.AUTH_URL
    ? process.env.AUTH_URL
    : getFallback('AUTH_URL');
  const NUXT_ENV_SSO_URL = process.env.NUXT_ENV_SSO_URL
    ? process.env.NUXT_ENV_SSO_URL
    : getFallback('NUXT_ENV_SSO_URL');

  let ORIGIN_URL;
  if (process.env.DASH_URL && process.env.SITE_URL) {
    ORIGIN_URL = `${process.env.DASH_URL}/#/login?origin=${process.env.SITE_URL}/contrate`;
  } else {
    ORIGIN_URL = FALLBACK_URL.ORIGIN_URL;
  }

  return {
    DASH_URL,
    SITE_URL,
    ECOM_URL,
    PARTNERS_URL,
    API_URL,
    TRACK_URL,
    AUTH_URL,
    ORIGIN_URL,
    NUXT_ENV_SSO_URL
  };
};

export default URLS;
