
import {
  required,
  minLength,
  maxLength,
  email
} from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

import {
  docModalAccreditation,
  documentationOptions
} from '@/models/DocModalAccreditation';
import { NETWORK_TYPE } from '@/models/NetworkType';
import { OFFER_BATH } from '@/models/OfferBath';
import { PERSON_TYPE } from '@/models/PersonType';
import AddressService from '@/services/address';
import {
  isNotUndefinedOrNullOrEmptyOrZero,
  isUndefinedOrNullOrEmptyOrZero
} from '@/services/string';
import Strings from '@/utils/strings';
import utils from '@/utils/utils';

export const accreditationForm = {
  personType: PERSON_TYPE.PJ,
  branch: NETWORK_TYPE.veterinaryClinic,
  name: '',
  cnpj: '',
  crmv: '',
  phone: '',
  email: '',
  address: {
    zipcode: '',
    street: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    state: ''
  },
  offersBath: ''
};
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      NETWORK_TYPE,
      PERSON_TYPE,
      loading: false,
      loadingCities: false,
      form: accreditationForm,
      isLoadingCep: false,
      cityOptions: [],
      stateOptions: [],
      lastCepCity: false,
      disabled: {
        addressCity: false,
        addressState: false
      },
      OFFER_BATH,
      requestAccreditationError: false,
      requestAccreditationErrorMessage: '',
      openDocumentModal: false,
      modalContent: '',
      selectedDocumentationOption: null,
      documentationOptions,
      modalDocsError: false
    };
  },
  computed: {
    ...mapGetters({
      getPartner: 'getParceiro'
    })
  },
  mounted() {
    this.$axios
      .$get('/location/states')
      .then((res) => res.data)
      .then((states) => {
        this.stateOptions = states;
      })
      .catch((error) => {
        if (error) {
          this.$root.$emit('toast:open', {
            message:
              'Ops! Erro ao carregar estados. Por favor atualizar a página.'
          });
        }
      });
  },
  methods: {
    requestAccreditation() {
      if (!this.selectedDocumentationOption) {
        this.modalDocsError = true;

        const element = document.getElementById('modalDocsAlert');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
        return;
      }

      if (!this.$v.form.$invalid) {
        this.requestAccreditationError = false;
        this.requestAccreditationErrorMessage = '';
        this.loading = true;

        const params = {
          person_type: this.form.personType,
          name: this.form.name,
          cnpj: this.form.cnpj,
          phone: Strings.sanitizePhone(this.form.phone),
          email: this.form.email,
          type: this.form.branch,
          crmv: this.form.crmv,
          zipcode: this.form.address.zipcode,
          street: this.form.address.street,
          number: this.form.address.number,
          complement: this.form.address.complement,
          offers_bath: this.form.offersBath,
          neighborhood: this.form.address.neighborhood,
          city: this.cityOptions.find(
            (city) => city.id === Number(this.form.address.city)
          ).name,
          state: this.form.address.state,
          origin: process.env.SITE_URL,
          required_docs: this.selectedDocumentationOption
        };

        const success = {
          name: 'drawerSuccess',
          header: 'Solicitação recebida',
          headerIcon: 'paw-hospital',
          title: 'Recebemos sua solicitação de credenciamento!',
          description: `
                <p class="text-center">
                Ficamos muito felizes pelo seu interesse em fazer parte da nossa rede.
                <br /><br />
                <strong>
                  Nossa equipe técnica irá analisar o seu<wbr /> cadastro e entraremos em
                  contato em até 7 dias úteis.
                </strong>
                <br /><br />
                Fique atento ao seu email, inclusive na <wbr />caixa de spam/lixo
                eletrônico.
              </p>
            `
        };

        this.$axios
          .$post('/lead/clinic', params)
          .then(() => {
            this.$nuxt.$emit('show:modal', success);
            this.$root.$emit('notify-event', 'cadastro_credenciado');
            this.form = accreditationForm;
            this.$emit('close');
          })
          .catch((error) => {
            this.requestAccreditationError = true;
            this.requestAccreditationErrorMessage = utils.getErrorMessage(
              error,
              'Erro ao enviar solicitação de credenciamento'
            );
            this.$nextTick(() => {
              this.$refs.alert.$el.scrollIntoView({ behavior: 'smooth' });
            });
          })
          .finally(() => {
            this.loading = false;
            this.openDocumentModal = false;
          });
      }
    },
    searchCep() {
      this.$v.form.address.zipcode.$touch();

      if (
        !this.form.address.zipcode ||
        this.form.address.zipcode.length < 9 ||
        isUndefinedOrNullOrEmptyOrZero(this.form.address.zipcode)
      ) {
        return;
      }

      this.isLoadingCep = true;
      this.lastCepCity = false;

      new AddressService()
        .getCEP(this, this.form.address.zipcode)
        .then((res) => res.data)
        .then((address) => {
          if (address.erro) {
            throw new Error('CEP não encontrado');
          }

          this.form.address.neighborhood = address.neighborhood;
          this.form.address.street = address.street;
          this.form.address.state = address.state_short;
          this.lastCepCity = address.city;

          this.disableCityAndStateFields();

          this.fetchCities(this.form.address.state);
          this.$refs.addressNumber &&
            this.$refs.addressNumber.$el.children[0].focus();
        })
        .catch((error) => {
          this.clearAddress();
          this.$root.$emit('toast:open', { message: error.message });
        })
        .finally(() => {
          this.isLoadingCep = false;
        });
    },
    disableCityAndStateFields() {
      this.disabled.addressCity = true;
      this.disabled.addressState = true;
    },
    fetchCities(uf) {
      if (!uf) {
        this.cityOptions = [];
        return;
      }
      const params = { isAll: true };

      this.$axios
        .get('/location/states/' + uf + '/cities', { params })
        .then((res) => res.data.data)
        .then((cities) => {
          this.cityOptions = cities;
          return this.cityOptions.find(
            (city) =>
              Strings.sanitize(city.name) === Strings.sanitize(this.lastCepCity)
          );
        })
        .then((city) => {
          this.$nextTick(() => {
            this.form.address.city = city ? city.id.toString() : null;
          });
        })
        .catch((err) => console.error(err));
    },
    clearAddress() {
      this.form.zipcode = null;
      this.form.address.street = null;
      this.form.address.number = null;
      this.form.address.state = null;
      this.form.address.city = null;
      this.form.address.neighborhood = null;
      this.form.address.complement = null;
      this.lastCepCity = false;
      this.disabled.addressCity = false;
      this.disabled.addressState = false;
    },
    openDocModal() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.modalContent = docModalAccreditation(this.form.branch);

        this.openDocumentModal = true;
      }
    },
    onClose() {
      this.modalDocsError = false;
      this.openDocumentModal = false;
    },
    setModalDocsErrorToFalse() {
      this.modalDocsError = false;
    }
  },
  validations() {
    return {
      form: {
        branch: { required, isNotUndefinedOrNullOrEmptyOrZero },
        offersBath: { required, isNotUndefinedOrNullOrEmptyOrZero },
        name: { required, isNotUndefinedOrNullOrEmptyOrZero },
        cnpj: { required, isNotUndefinedOrNullOrEmptyOrZero },
        phone: { required, minLength: minLength(14), maxLength: maxLength(15) },
        email: { required, email },
        crmv: { required, isNotUndefinedOrNullOrEmptyOrZero },
        address: {
          zipcode: {
            required,
            minLength: minLength(8)
          },
          number: {
            required,
            minLength: minLength(1),
            maxLength: maxLength(12)
          },
          street: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(100)
          },
          state: {
            required,
            minLength: minLength(1),
            maxLength: maxLength(50)
          },
          city: {
            required,
            minLength: minLength(1),
            maxLength: maxLength(100)
          },
          neighborhood: {
            required,
            minLength: minLength(1),
            maxLength: maxLength(100)
          }
        }
      }
    };
  }
};
