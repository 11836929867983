
import VueCookie from 'vue-cookie';
import {
  email,
  maxLength,
  minLength,
  required
} from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

import { NETWORK_TYPE } from '@/models/NetworkType';
import { PERSON_TYPE } from '@/models/PersonType';
import { isNotUndefinedOrNullOrEmptyOrZero } from '@/services/string';

export const affiliateForm = {
  type: PERSON_TYPE.PF,
  companyName: '',
  name: '',
  phone: '',
  email: ''
};
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      NETWORK_TYPE,
      PERSON_TYPE,
      loading: false,
      form: affiliateForm
    };
  },
  computed: {
    ...mapGetters({
      guestRegion: 'getGuestRegion'
    })
  },
  methods: {
    async beAffiliate() {
      this.$v.$touch();
      if (!this.$v.form.$invalid) {
        this.loading = true;

        const token = await this.$recaptcha.execute('login').catch((err) => {
          this.loading = false;
          if (err) {
            const message = 'Aguarde alguns segundos e tente novamente.';
            this.$root.$emit('toast:open', {
              message,
              type: 'error'
            });
          }
        });

        const params = {
          token,
          name: this.form.companyName
            ? `${this.form.companyName} | ${this.form.name}`
            : this.form.name,
          email: this.form.email,
          phone: this.form.phone,
          type: Object.keys(PERSON_TYPE).find(
            (key) => PERSON_TYPE[key] === this.form.type
          ),
          regionId: this.guestRegion ? this.guestRegion.id : null,
          utms: JSON.parse(VueCookie.get('_nftrk')),
          rdtrk: JSON.parse(VueCookie.get('rdtrk')),
          trfsrc: VueCookie.get('__trf.src'),
          origin: process.env.SITE_URL
        };

        const success = {
          name: 'drawerSuccess',
          header: 'Cadastro realizado',
          headerIcon: 'paw-heart',
          title: 'Cadastro realizado com sucesso!',
          description: `<p class="text-center">Enviamos para o seu email o contato para mais informações e instruções dos próximos passos.</p>`
        };

        this.$axios
          .post('affiliates', params)
          .then(() => {
            this.loading = false;
            this.$nuxt.$emit('show:modal', success);
            this.$root.$emit('notify-event', 'cadastro_afiliado');
            this.form = affiliateForm;
            this.$emit('close');
          })
          .catch((err) => {
            this.loading = false;
            if (
              err.response &&
              err.response.data &&
              err.response.data.message
            ) {
              this.$root.$emit('toast:open', {
                message: err.response.data.message
              });
            } else {
              const message =
                'Ops! Não foi possível registrar as informações digitadas.';
              this.$root.$emit('toast:open', {
                message,
                type: 'error'
              });
            }
          });
      }
    }
  },
  validations() {
    return {
      form: {
        name: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(150),
          isNotUndefinedOrNullOrEmptyOrZero
        },
        phone: {
          required,
          minLength: minLength(8),
          maxLength: maxLength(16),
          isNotUndefinedOrNullOrEmptyOrZero
        },
        email: {
          required,
          email,
          minLength: minLength(2),
          maxLength: maxLength(150),
          isNotUndefinedOrNullOrEmptyOrZero
        },
        type: {
          required
        }
      }
    };
  }
};
