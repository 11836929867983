const docModalAccreditation = (option) => {
  switch (option) {
    case 'Consultório':
    case 'Clínica com Cirurgia':
    case 'Imagem':
    case '24h':
    case 'Laboratório':
      return {
        docs: [
          {
            description: 'Cadastro no SIPEAGRO'
          },
          {
            description: 'Registro do estabelecimento no CRMV'
          },
          {
            description: 'Alvará de Funcionamento'
          },
          {
            description: 'Alvará da vigilância sanitária'
          },
          {
            description:
              'Licença para equipamentos (Raio-X, Tomografia, Ressonância)'
          },
          {
            description: 'AVCB (Auto de Vistoria do Corpo de Bombeiros)'
          },
          {
            description: 'PGRSSA'
          },
          {
            description: 'POP e Manual de Boas Práticas'
          },
          {
            description:
              'Documentação pessoal do responsável técnico (Uma foto ou cópia da célula do CRMV, Nome Completo, CPF, RG e  Cadastro no SIPEAGRO)'
          }
        ]
      };
    case 'Fisioterapia e/ou Acupuntura':
    case 'Anestesista':
    case 'Especialista Volante':
    case 'Volante de imagem':
      return {
        docs: [
          {
            description: 'Uma foto ou cópia da célula do CRMV'
          },
          {
            description: 'Nome Completo'
          },
          {
            description: 'CPF'
          },
          {
            description: 'RG'
          },
          {
            description: 'Cadastro no SIPEAGRO'
          },
          {
            description:
              'Certificado de Conclusão referente à especialização, residência, mestrado ou doutorado'
          }
        ]
      };
    case 'Veterinário a domicílio':
      return {
        docs: [
          {
            description: 'Uma foto ou cópia da célula do CRMV'
          },
          {
            description: 'Nome Completo'
          },
          {
            description: 'CPF'
          },
          {
            description: 'RG'
          },
          {
            description: 'Cadastro no SIPEAGRO'
          }
        ]
      };
    case 'Petshop':
      return {
        docs: [
          {
            description: 'Alvará de Funcionamento'
          },
          {
            description: 'Alvará da vigilância sanitária'
          },
          {
            description: 'AVCB (Auto de Vistoria do Corpo de Bombeiros)'
          },
          {
            description: 'POP e Manual de Boas Práticas'
          }
        ]
      };
    default:
      return {
        docs: [
          {
            description: 'Cadastro no SIPEAGRO'
          },
          {
            description: 'Registro do estabelecimento no CRMV'
          },
          {
            description: 'Alvará de Funcionamento'
          },
          {
            description: 'Alvará da vigilância sanitária'
          },
          {
            description:
              'Licença para equipamentos (Raio-X, Tomografia, Ressonância)'
          },
          {
            description: 'AVCB (Auto de Vistoria do Corpo de Bombeiros)'
          },
          {
            description: 'PGRSSA'
          },
          {
            description: 'POP e Manual de Boas Práticas'
          },
          {
            description: 'Certificado de Pós Graduação na Especialidade'
          }
        ]
      };
  }
};

const documentationOptions = [
  {
    id: 1,
    name: 'Tenho todos os documentos'
  },
  {
    id: 2,
    name: 'Tenho parte dos documentos'
  },
  {
    id: 3,
    name: 'Não tenho os documentos'
  }
];

export { docModalAccreditation, documentationOptions };
