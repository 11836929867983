
import VueCookie from 'vue-cookie';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import { OwnPlansPartners } from '@/models/OwnPlansPartners';
import { PartnersSource } from '@/models/PurchaseSource';
import { dynamicYieldCampaign } from '@/services/dynamicYield';
import { dinamicYieldApiSelectors } from '@/services/dynamicYield/campaigns';
import promoService from '@/services/promoService';
import { filterAndConvertToString } from '@/utils/navigation';
import { loadRdStationScript } from '@/utils/rdstationScript';
import Track from '@/utils/track';

export default {
  data() {
    return {
      user: null,
      showMenuMobile: false,
      showAlertCookie: false,
      horas: 23,
      minutos: 59,
      segundos: 59
    };
  },
  computed: {
    ...mapGetters({
      regions: 'getRegions',
      guestRegion: 'getGuestRegion',
      callbackStatus: 'getCallbackStatus',
      promoCode: 'getPromoCode',
      isSetAlertCookie: 'getAlertCookieStatus',
      userData: 'customerData',
      getPartner: 'getParceiro',
      isItau: 'getIsItau',
      promoDetails: 'getPromoDetails',
      getPartnerCode: 'getPartnerCode',
      partnerWhitelabelConfig: 'getPartnerWhitelabelConfig',
      getVariantSelectorsPlans: 'getVariantSelectorsPlans',
      getVariantDownloadApp: 'getVariantDownloadApp'
    }),
    guestRegion() {
      const guestRegion = this.$store.state.guestRegion;
      if (!guestRegion) {
        return {};
      }
      return guestRegion;
    },
    showNewNavbarDesktop() {
      return this.$route?.query?.newLP === 'true' && this.$device.isDesktop;
    },
    showNavbar() {
      return this.$device.isDesktop && !this.showNewNavbarDesktop;
    }
  },
  watch: {
    isSetAlertCookie() {
      this.showAlertCookie = false;
    },
    guestRegion() {
      this.checkPromotion();
      this.setCustomerType();
    },
    promoDetails() {
      this.setCustomerType();
    }
  },
  async mounted() {
    Track.watchNotifyEvent(this.$root, this.userData);

    if (this.$route?.query?.utm_campaign) {
      this.$store.commit('set_utm_campaign', this.$route.query.utm_campaign);
    }
    if (this.$route?.query?.utm_source) {
      sessionStorage.setItem(
        'utm_source',
        filterAndConvertToString(this.$route.query.utm_source)
      );
      await this.checkPartnerCode();
    }

    if (this.$route?.query?.checkout === 'default') {
      this.$store.commit('set_checkoutDefault', true);
    }

    const cookieUtmSource = sessionStorage.getItem('utm_source');

    const shouldLoadRdStation = !PartnersSource.HIDE_WHATSAPP_BUTTON_LIST.some(
      (source) => cookieUtmSource?.includes(source)
    );

    if (
      shouldLoadRdStation &&
      !this.partnerWhitelabelConfig.hideWhatsappButton
    ) {
      loadRdStationScript();
    }
    const vm = this;

    if (!this.isSetAlertCookie && this.$route.path !== '/transparencia') {
      this.showAlertCookie = true;
    }

    vm.$root.$on('open:menu-mobile', () => {
      vm.showMenuMobile = true;
    });

    vm.$root.$on('close:menu-mobile', () => {
      vm.showMenuMobile = false;
    });

    if (!this.regions || this.regions?.length <= 0) {
      await this.loadRegions();
    }

    this.setUTMs();
    this.setMeliuz();
    this.checkPass();
    await this.checkPartnerCode();
    await this.checkPromotion();
    this.checkCallback();

    if (window?.clarity) window.clarity('identify', this.userData.email);

    !this.promoCode && this.loadPlans();

    this.setCustomerType();
    this.fetchAdditionalServices('discount_club');
    this.setSelectorsPlansVariantDY();
    this.setDownloadAppVariantDY();
  },
  methods: {
    ...mapMutations([
      'set_promoCode',
      'set_promoDetails',
      'set_partner',
      'set_userType',
      'set_partnerWhitelabelConfig',
      'set_variantSelectorsPlans',
      'set_variantDownloadApp'
    ]),
    ...mapActions({
      loadPlans: 'loadPlans',
      loadRegions: 'loadRegions',
      setGuestRegion: 'setGuestRegion',
      setPromotionCode: 'setPromotionCode',
      setPartnerCode: 'setPartnerCode',
      setPassCode: 'setPassCode',
      setPartnerConfig: 'setPartnerConfig',
      fetchAdditionalServices: 'additionalServices/fetchAdditionalServices'
    }),
    setMeliuz() {
      const query = this.$route.query;
      if (query && query.utm_source && query.xtra) {
        VueCookie.set(
          '_meliuz',
          JSON.stringify({
            source: query.utm_source,
            xtra: query.xtra
          }),
          { expires: '1M', secure: true }
        );
      }
    },
    setUTMs() {
      const query = this.$route.query;
      const nftrk = JSON.parse(VueCookie.get('_nftrk'));
      VueCookie.set(
        '_nftrk',
        JSON.stringify({
          source: (query && query.utm_source) || '',
          medium: (query && query.utm_medium) || '',
          campaign:
            (query && query.utm_campaign) || (nftrk ? nftrk.utm_campaign : ''),
          content:
            (query && query.utm_content) || (nftrk ? nftrk.utm_content : '')
        }),
        { expires: '1D', secure: true }
      );
    },
    checkPass() {
      if (this.$route.query?.pass) {
        this.setPassCode(this.$route.query.utm_source);
      }
    },
    async checkPartnerCode() {
      if (this.$route.query?.utm_source) {
        this.setPartnerCode(this.$route.query.utm_source);
        try {
          await this.setPartnerConfig(this.$route.query.utm_source);
        } catch (e) {
          this.set_partnerWhitelabelConfig({
            hideInsideSalesPhone: false,
            hidePartnerMenuOptions: false,
            hideWhatsappButton: false
          });
        }
      }
    },
    checkPromotion() {
      if (this.$route.query?.promocao) {
        this.setPromotionCode(this.$route.query.promocao);
      }

      if (this.promoCode) {
        promoService
          .getPromotionDetails(this, this.promoCode)
          .then((promoDetails) => {
            this.set_promoCode(promoDetails.slug);
            this.set_promoDetails(promoDetails);

            const isOwnPlansPartner = OwnPlansPartners.includes(this.promoCode);

            if (isOwnPlansPartner || this.isItau) return;
            this.set_partner({});
          })
          .catch(() => {
            this.set_promoCode(null);
            this.set_promoDetails({});
          })
          .finally(() => {
            this.loadPlans();
          });
      }
    },
    checkCallback() {
      this.$axios
        .get('/callback/is-active')
        .then((res) => {
          this.$store.commit('set_callbackStatus', res.data.active);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    setCustomerType() {
      const isOwnPlansPartner = OwnPlansPartners.includes(this.promoCode);
      const isB2C =
        !this.getPartner?.status &&
        !isOwnPlansPartner &&
        !this.promoCode &&
        !this.promoDetails?.slug &&
        !this.getPartnerCode;

      this.set_userType(isB2C ? 'B2C' : '');
    },
    async setSelectorsPlansVariantDY() {
      const campaignName = dinamicYieldApiSelectors.selectorsPlans;
      await dynamicYieldCampaign(
        this.getVariantSelectorsPlans,
        campaignName,
        'set_variantSelectorsPlans'
      );
    },
    async setDownloadAppVariantDY() {
      const campaignName = dinamicYieldApiSelectors.downloadApp;
      await dynamicYieldCampaign(
        this.getVariantDownloadApp,
        campaignName,
        'set_variantDownloadApp'
      );
    }
  }
};
