
export default {
  name: 'Header',
  props: {
    step: {
      type: Number,
      default: 1
    },
    authenticated: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    stepName() {
      if (this.step === 3) {
        return 'Pagamento';
      }
      if (this.step === 2 && !this.authenticated) {
        return 'Dados pessoais';
      }
      return 'Planos';
    },
    stepNamePosition() {
      if (this.step === 3) {
        return 'has-text-right';
      }
      if (this.step === 2) {
        return 'has-text-centered';
      }
      return '';
    }
  }
};
