export const PurchaseSource = {
  BOT: 'bot-whatsapp',
  PARTNER_CLIENT: 'partner',
  PARTNER_CLIENT_LIST: {
    AON: 'aon',
    UNIMED: 'unimed',
    VIVO: 'vivo'
  },
  isValid(partner) {
    return Object.values(this.PARTNER_CLIENT_LIST).includes(partner);
  }
};

export const PartnersSource = {
  HIDE_INSIDE_SALES_INFO_LIST: {
    AUXILIADORA: 'auxiliadora'
  },
  HIDE_WHATSAPP_BUTTON_LIST: ['itau', 'auxiliadora'],
  isPartnerOnHideInsideSalesInfoList(partner) {
    return Object.values(this.HIDE_INSIDE_SALES_INFO_LIST).includes(partner);
  }
};
