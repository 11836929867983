import { render, staticRenderFns } from "./ModalAdditional.vue?vue&type=template&id=10c757bf&scoped=true"
import script from "./ModalAdditional.vue?vue&type=script&lang=js"
export * from "./ModalAdditional.vue?vue&type=script&lang=js"
import style0 from "./ModalAdditional.vue?vue&type=style&index=0&id=10c757bf&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10c757bf",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CaLoading: require('/app/node_modules/@petlove/caramelo-vue-components/src/Loading/index.vue').default,NofaroTooltip: require('/app/components/NofaroTooltip.vue').default,CaModal: require('/app/node_modules/@petlove/caramelo-vue-components/src/Modal/index.vue').default})
