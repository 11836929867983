export default {
  plans(ctx, region, raffle, options = {}) {
    const { pass, partner } = options;

    const current_date = this.getDate();
    const params = {
      current_date,
      region,
      raffle,
      ...(pass && { pass }),
      ...(partner && { partner })
    };

    return ctx.$axios.get('plans', { params });
  },

  coverageDetails(ctx) {
    return ctx.$axios.get('comparative-table').then((res) => res.data);
  },
  getDate() {
    return new Date().getDate();
  }
};
