
import { mapGetters } from 'vuex';

import IconWhats from '@/assets/images/home/black-friday/icon-whats.svg';
import IconPhone from '@/assets/images/pet-tag/icon-phone.svg';
import RegionFilter from '@/components/header/RegionFilter.vue';
import URLS from '@/models/Url';
import AuthService from '@/services/auth';
import Gtm from '@/utils/gtm';
import { navigateToCheckout, persistNavigationQuery } from '@/utils/navigation';

const authService = new AuthService();
export default {
  components: {
    RegionFilter,
    IconWhats,
    IconPhone
  },
  computed: {
    ...mapGetters({
      isItau: 'getIsItau',
      guestRegion: 'getGuestRegion',
      memberFromBroker: 'getMemberFromBroker',
      hidePartnerMenuOptions: 'getHidePartnerMenuOptions',
      partnerWhitelabelConfig: 'getPartnerWhitelabelConfig'
    }),
    isAccreditNetworkPage() {
      return this.$route.fullPath.includes('rede-credenciada');
    },
    isLogged() {
      return authService.isLogged();
    },
    dashUrl() {
      return URLS().DASH_URL;
    },
    partnerUrl() {
      return URLS().PARTNERS_URL;
    },
    guestRegionName() {
      return this.guestRegion?.name || '';
    },
    showPetlovePlans() {
      return !this.isItau && !this.memberFromBroker;
    },
    showSalesInfo() {
      return (
        !this.isItau &&
        !this.memberFromBroker &&
        !this.partnerWhitelabelConfig.hideInsideSalesPhone
      );
    },
    normalizedPhone() {
      return this.guestRegion?.st_cellphone?.replace(/\D/g, '');
    }
  },
  watch: {
    guestRegion(value, oldValue) {
      if (value !== oldValue) this.afterSetRegion();
    }
  },
  mounted() {
    document.documentElement.classList.add('is-clipped');

    this.$nuxt.$on('show:modal', (modal) => {
      if (modal === 'selectRegion') this.close();
    });
  },
  beforeDestroy() {
    document.documentElement.classList.remove('is-clipped');
  },
  methods: {
    close() {
      this.$root.$emit('close:menu-mobile');
    },
    afterSetRegion() {
      this.$store.commit('set_user_attribute', { key: 'pets', value: [] });
    },
    goToPlanSection() {
      if (this.$route.path !== '/') {
        this.$router.push({
          path: '/',
          query: persistNavigationQuery(this.$route.query)
        });
        setTimeout(() => this.$scrollTo('#planos-de-saude'), 300);
      } else {
        this.$scrollTo('#planos-de-saude');
      }
    },
    goToContrate() {
      location.href = navigateToCheckout({
        params: this.$route.query
      });
    },
    sendCustomEvent(category, action, label) {
      Gtm.sendCustomEvent(category, action, label);
    }
  }
};
