
export default {
  name: 'ModalVideoYouTube',
  props: {
    youtubeId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      showVideo: false,
      player: '',
      screenWidth: window.screen.width
    };
  },
  computed: {
    youtubeScriptAdded() {
      return this.$store.state.youtubeScriptAdded;
    },
    youtubeApiReady() {
      return this.$store.state.youtubeApiReady;
    }
  },
  mounted() {
    window.onYouTubePlayerAPIReady = () => {
      this.$store.commit('set_youtubeApiReady', true);
    };
    this.loadVideo();
  },
  methods: {
    onClose() {
      if (this.player && this.player.stopVideo) {
        this.player.stopVideo();
      }
      this.$emit('close');
    },
    /**
     * This code loads the IFrame Player API code asynchronously.
     * https://developers.google.com/youtube/iframe_api_reference#Getting_Started
     */
    loadYouTubeScript() {
      if (!this.youtubeScriptAdded) {
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/player_api';
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        this.$store.commit('set_youtubeScriptAdded', true);
      }
    },
    /**
     * This function creates an <iframe> (and YouTube player) after the API code downloads.
     * https://developers.google.com/youtube/iframe_api_reference#Getting_Started
     */
    createIframe() {
      const modalVideoComponent = this;
      this.player = new window.YT.Player(this.youtubeId, {
        videoId: this.youtubeId,
        events: {
          onReady(event) {
            modalVideoComponent.loading = false;
            setTimeout(() => (modalVideoComponent.showVideo = true), 1100);
            event.target.setVolume(100);
            event.target.playVideo();
          }
        }
      });

      if (this.screenWidth <= 800) {
        this.player.setSize({ width: 320, height: 180 });
      }
    },
    loadVideo() {
      this.loadYouTubeScript();
      const tryLoadVideo = () => {
        if (this.youtubeApiReady) {
          if (!this.player) this.createIframe();
          else this.loading = false;
        } else {
          setTimeout(tryLoadVideo, 500);
        }
      };
      setTimeout(tryLoadVideo, 500);
    }
  }
};
