const sanitize = (string) => {
  if (!string) return;
  const charactersToSanitize = [
    {
      regex: /[àáâã]/g,
      replace: 'a'
    },
    {
      regex: /[èéê]/g,
      replace: 'e'
    },
    {
      regex: /[íì]/g,
      replace: 'i'
    },
    {
      regex: /[óòôõ]/g,
      replace: 'o'
    },
    {
      regex: /[úùü]/g,
      replace: 'u'
    },
    {
      regex: /[ç]/g,
      replace: 'c'
    },
    {
      regex: /[ñ]/gi,
      replace: 'n'
    },
    {
      regex: /[^a-z0-9]/gi,
      replace: ''
    }
  ];
  string = string.toLowerCase();

  charactersToSanitize.forEach((sanitize) => {
    string = string.replace(sanitize.regex, sanitize.replace);
  });

  return string;
};

const removeWhiteSpaces = (value) => {
  return value?.replace(/ /g, '');
};

const stringToSlug = (str) => {
  str = str?.toLowerCase();
  str = str?.normalize('NFD').replace(/[\u0300-\u036F]/g, '');
  str = str?.replace(/[^a-z0-9\s]/g, '');
  str = str?.replace(/\s+/g, '-');
  return str;
};

const sanitizePhone = (phone) => {
  if (!phone) return;
  return phone.replace(/[^0-9]/g, '');
};

const sanitizeCpf = (cpf) => {
  if (!cpf) return;
  return cpf?.replace(/[^\d]/g, '');
};

export default {
  removeWhiteSpaces,
  sanitize,
  stringToSlug,
  sanitizePhone,
  sanitizeCpf
};
