import VueCookie from 'vue-cookie';
export const todayPlus1Day = new Date().setDate(new Date().getDate() + 1);
export const todayPlus7Days = new Date().setDate(new Date().getDate() + 7);

export function setItem(key, value, storageKey) {
  const currentExpires = getCurrentExpiresCookie(storageKey);
  return VueCookie.set(key, value, {
    expires: currentExpires,
    secure: true
  });
}

export function getItem(key) {
  return VueCookie.get(key);
}

export function removeItem(key) {
  return VueCookie.delete(key);
}

export function getCurrentExpiresCookie(storageKey) {
  const storeCookie = VueCookie.get(storageKey);
  const expires = JSON.parse(storeCookie)?.expires;
  if (expires) {
    return new Date(expires);
  }
}
