import Vue from 'vue';

class AddressService {
  async getCEP(ctx, cep) {
    cep = cep.replace('-', '');
    try {
      return await this.getAddressByPetloveApi(ctx, cep);
    } catch (error) {
      Vue.prototype.$honeybadger.notify(error);
      try {
        const response = await this.getAddressByViaCep(ctx, cep);
        return response;
      } catch (error) {
        Vue.prototype.$honeybadger.notify(error);
        return {
          neighborhood: '',
          street: '',
          city: '',
          state: ''
        };
      }
    }
  }

  getAddressByPetloveApi(ctx, cep) {
    return ctx.$axios.get(`${process.env.PETLOVE_API_URL}/postal_code/${cep}`);
  }

  async getAddressByViaCep(ctx, cep) {
    const { data } = await ctx.$axios.get(
      `${process.env.VIA_CEP_API_URL}/ws/${cep}/json`
    );

    return {
      data: {
        neighborhood: data.bairro,
        street: data.logradouro,
        city: data.localidade,
        state_short: data.uf
      }
    };
  }
}

export default AddressService;
