import { render, staticRenderFns } from "./MobileMenu.vue?vue&type=template&id=535aa537&scoped=true"
import script from "./MobileMenu.vue?vue&type=script&lang=js"
export * from "./MobileMenu.vue?vue&type=script&lang=js"
import style0 from "./MobileMenu.vue?vue&type=style&index=0&id=535aa537&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "535aa537",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CaIconButton: require('/app/node_modules/@petlove/caramelo-vue-components/src/IconButton/index.vue').default,CaButton: require('/app/node_modules/@petlove/caramelo-vue-components/src/Button/index.vue').default,RegionFilter: require('/app/components/header/RegionFilter.vue').default})
