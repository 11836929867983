
import PetsSayHello from '@/assets/images/pets-say-hello.svg';

export default {
  name: 'Error404',
  components: {
    PetsSayHello
  },
  props: {
    error: {
      type: Object,
      default: () => {}
    }
  }
};
