
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    header: {
      type: String,
      default: 'Sucesso'
    },
    headerIcon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: 'Requisição efetuada com sucesso!'
    },
    description: {
      type: String,
      default: ''
    }
  },
  computed: {
    componentId() {
      return `success-drawer__description`;
    }
  },
  watch: {
    description(value, oldValue) {
      if (value && value !== oldValue) {
        document.getElementById(this.componentId).innerHTML = this.description;
      }
    }
  }
};
