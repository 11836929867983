export function navigateToCheckout({ params = {} } = {}, url = '/contrate') {
  const queryString = Object.keys(params)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join('&');

  return `${url}?${queryString}`;
}

export const filterAndConvertToString = (value) => {
  if (Array.isArray(value)) {
    const filteredValue = value.find((v) => !v.includes('?'));
    return filteredValue ? filteredValue.split('?')[0] : '';
  }
  return typeof value === 'string' ? value.split('?')[0] : value;
};

export function persistNavigationQuery(routeQuery) {
  let { utm_source, pass, promocao } = routeQuery;

  if (typeof window !== 'undefined' && window.$nuxt) {
    if (!utm_source) {
      utm_source = window.$nuxt.$store.getters.getPartnerCode;
    }

    if (!pass) {
      pass = window.$nuxt.$store.getters.getPass;
    }

    if (!promocao) {
      promocao = window.$nuxt.$store.getters.getPromoCode;
    }
  }

  const filteredUtmSource = filterAndConvertToString(utm_source);
  const filteredPass = filterAndConvertToString(pass);
  const filteredPromocao = filterAndConvertToString(promocao);

  return {
    ...(filteredUtmSource && { utm_source: filteredUtmSource }),
    ...(filteredPass && { pass: filteredPass }),
    ...(filteredPromocao && { promocao: filteredPromocao })
  };
}
