import { render, staticRenderFns } from "./MenuMobile.vue?vue&type=template&id=1243d640&scoped=true"
import script from "./MenuMobile.vue?vue&type=script&lang=js"
export * from "./MenuMobile.vue?vue&type=script&lang=js"
import style0 from "./MenuMobile.vue?vue&type=style&index=0&id=1243d640&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1243d640",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CaButton: require('/app/node_modules/@petlove/caramelo-vue-components/src/Button/index.vue').default,CaIcon: require('/app/node_modules/@petlove/caramelo-vue-components/src/Icon/index.vue').default,CaChip: require('/app/node_modules/@petlove/caramelo-vue-components/src/Chip/index.vue').default,CaIconButton: require('/app/node_modules/@petlove/caramelo-vue-components/src/IconButton/index.vue').default})
