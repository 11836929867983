
import { mapGetters } from 'vuex';

export default {
  props: {
    value: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      guestRegion: 'getGuestRegion'
    }),
    errorRegionCheckout() {
      return this.$store.state.errorRegionCheckout;
    }
  },
  watch: {
    guestRegion(value, oldValue) {
      if (value !== oldValue) this.afterSetRegion();
    }
  },
  methods: {
    selectRegion() {
      this.$nuxt.$emit('show:modal', 'selectRegion');
    },
    afterSetRegion() {
      this.$store.commit('set_user_attribute', { key: 'pets', value: [] });
      if (this.errorRegionCheckout) {
        this.$router.push('/contrate');
      }
    }
  }
};
