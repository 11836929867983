import Vue from 'vue';

import { ADDON_CLUB } from '@/utils/DiscountClub';
import { isBundleRegions } from '@/utils/bundleRegions';

export default class BundleHandler {
  static setBundleStates(bundleType) {
    if (typeof window === 'undefined') {
      return;
    }

    window.$nuxt.$store.commit(
      'set_showBundleAddOn',
      bundleType === ADDON_CLUB
    );
  }

  static handleBundleVarations(state) {
    if (typeof window === 'undefined') {
      return;
    }
    try {
      if (isBundleRegions(state.guestRegion)) {
        this.setBundleStates(ADDON_CLUB);
        return;
      } else {
        this.setBundleStates(null);
        window.$nuxt.$store.commit('set_isDiscountClub', false);
      }
    } catch (error) {
      Vue?.prototype?.$honeybadger?.notify(error);
    }
  }
}
