import { render, staticRenderFns } from "./HeaderSimple.vue?vue&type=template&id=67555e58&scoped=true"
import script from "./HeaderSimple.vue?vue&type=script&lang=js"
export * from "./HeaderSimple.vue?vue&type=script&lang=js"
import style0 from "./HeaderSimple.vue?vue&type=style&index=0&id=67555e58&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67555e58",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UnionLogos: require('/app/components/footer/UnionLogos.vue').default,CaDivisor: require('/app/node_modules/@petlove/caramelo-vue-components/src/Divisor/index.vue').default})
