
/**
 * Para abrir o toast this.$root.$emit('toast:open', options)
 *
 * options:
 *  - message [obrigatório]  <string> Mensagem a ser mostrada
 *  - type    [opcional]    <string> 'success', 'error' ou ''. Default ''
 *  - time    [opcional]    <number> tempo em milisegundos que toast deve ser mostrado
 */
export default {
  name: 'NofaroToast',
  data() {
    return {
      isOpen: false,
      message: '',
      type: '',
      timerClose: false
    };
  },
  mounted() {
    this.$root.$on('toast:open', (data) => {
      this.openToast(data);
    });
    this.$root.$on('toast:close', (data) => {
      this.closeToast(data);
    });
  },
  methods: {
    openToast(data) {
      if (this.timerClose) {
        clearTimeout(this.timerClose);
      }
      this.isOpen = true;
      this.message = data.message;
      this.type = data.type ? data.type : '';
      this.timerClose = setTimeout(
        () => {
          this.isOpen = false;
        },
        data.time ? parseInt(data.time) : 3000
      );
    },
    closeToast() {
      this.isOpen = false;
    }
  }
};
