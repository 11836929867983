import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _442485af = () => interopDefault(import('../pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _6f330b9a = () => interopDefault(import('../pages/credenciamento/index.vue' /* webpackChunkName: "pages/credenciamento/index" */))
const _68340b77 = () => interopDefault(import('../pages/empresas/index.vue' /* webpackChunkName: "pages/empresas/index" */))
const _bdc07f3c = () => interopDefault(import('../pages/plano-de-saude-pet/index.vue' /* webpackChunkName: "pages/plano-de-saude-pet/index" */))
const _06431007 = () => interopDefault(import('../pages/politica-de-cookies.vue' /* webpackChunkName: "pages/politica-de-cookies" */))
const _1d37872e = () => interopDefault(import('../pages/politica-de-privacidade.vue' /* webpackChunkName: "pages/politica-de-privacidade" */))
const _3b26d2f5 = () => interopDefault(import('../pages/rede-credenciada/index.vue' /* webpackChunkName: "pages/rede-credenciada/index" */))
const _7043033c = () => interopDefault(import('../pages/simulador-de-coberturas/index.vue' /* webpackChunkName: "pages/simulador-de-coberturas/index" */))
const _3408a900 = () => interopDefault(import('../pages/simulador-de-precos/index.vue' /* webpackChunkName: "pages/simulador-de-precos/index" */))
const _e25f153c = () => interopDefault(import('../pages/transparencia/index.vue' /* webpackChunkName: "pages/transparencia/index" */))
const _500206f7 = () => interopDefault(import('../pages/contrate/pagar/index.vue' /* webpackChunkName: "pages/contrate/pagar/index" */))
const _2a1fbebb = () => interopDefault(import('../pages/contrate/sucesso/index.vue' /* webpackChunkName: "pages/contrate/sucesso/index" */))
const _350b8eb4 = () => interopDefault(import('../pages/promo/termos-e-condicoes.vue' /* webpackChunkName: "pages/promo/termos-e-condicoes" */))
const _42537274 = () => interopDefault(import('../pages/contrate/sucesso/download-app/index.vue' /* webpackChunkName: "pages/contrate/sucesso/download-app/index" */))
const _5f150816 = () => interopDefault(import('../pages/empresas/sections/SectionAccreditedNetwork.vue' /* webpackChunkName: "pages/empresas/sections/SectionAccreditedNetwork" */))
const _69ab21aa = () => interopDefault(import('../pages/empresas/sections/SectionBenefits.vue' /* webpackChunkName: "pages/empresas/sections/SectionBenefits" */))
const _42855aa1 = () => interopDefault(import('../pages/empresas/sections/SectionEcosystem.vue' /* webpackChunkName: "pages/empresas/sections/SectionEcosystem" */))
const _f10c4712 = () => interopDefault(import('../pages/empresas/sections/SectionFaq.vue' /* webpackChunkName: "pages/empresas/sections/SectionFaq" */))
const _1f53d8ea = () => interopDefault(import('../pages/empresas/sections/SectionPlans.vue' /* webpackChunkName: "pages/empresas/sections/SectionPlans" */))
const _68ce2e7c = () => interopDefault(import('../pages/empresas/sections/SectionReviews.vue' /* webpackChunkName: "pages/empresas/sections/SectionReviews" */))
const _4d88bc4e = () => interopDefault(import('../pages/empresas/sections/SectionSupportContent.vue' /* webpackChunkName: "pages/empresas/sections/SectionSupportContent" */))
const _3457efe3 = () => interopDefault(import('../pages/empresas/sections/SectionWhyOffer.vue' /* webpackChunkName: "pages/empresas/sections/SectionWhyOffer" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _013de4d1 = () => interopDefault(import('../pages/confirmacao/_message.vue' /* webpackChunkName: "pages/confirmacao/_message" */))
const _461f8cb2 = () => interopDefault(import('../pages/contrate/_id.vue' /* webpackChunkName: "pages/contrate/_id" */))
const _55ac032a = () => interopDefault(import('../pages/indicacao/_token.vue' /* webpackChunkName: "pages/indicacao/_token" */))
const _0bd30468 = () => interopDefault(import('../pages/localizador-pet/_id/index.vue' /* webpackChunkName: "pages/localizador-pet/_id/index" */))
const _13e34e63 = () => interopDefault(import('../pages/promocoes/_index.vue' /* webpackChunkName: "pages/promocoes/_index" */))
const _06971982 = () => interopDefault(import('../pages/rede-credenciada/_.vue' /* webpackChunkName: "pages/rede-credenciada/_" */))
const _ba5c7522 = () => interopDefault(import('../pages/plano-de-saude-pet/_.vue' /* webpackChunkName: "pages/plano-de-saude-pet/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/callback",
    component: _442485af,
    name: "callback"
  }, {
    path: "/credenciamento",
    component: _6f330b9a,
    name: "credenciamento"
  }, {
    path: "/empresas",
    component: _68340b77,
    name: "empresas"
  }, {
    path: "/plano-de-saude-pet",
    component: _bdc07f3c,
    name: "plano-de-saude-pet"
  }, {
    path: "/politica-de-cookies",
    component: _06431007,
    name: "politica-de-cookies"
  }, {
    path: "/politica-de-privacidade",
    component: _1d37872e,
    name: "politica-de-privacidade"
  }, {
    path: "/rede-credenciada",
    component: _3b26d2f5,
    name: "rede-credenciada"
  }, {
    path: "/simulador-de-coberturas",
    component: _7043033c,
    name: "simulador-de-coberturas"
  }, {
    path: "/simulador-de-precos",
    component: _3408a900,
    name: "simulador-de-precos"
  }, {
    path: "/transparencia",
    component: _e25f153c,
    name: "transparencia"
  }, {
    path: "/contrate/pagar",
    component: _500206f7,
    name: "contrate-pagar"
  }, {
    path: "/contrate/sucesso",
    component: _2a1fbebb,
    name: "contrate-sucesso"
  }, {
    path: "/promo/termos-e-condicoes",
    component: _350b8eb4,
    name: "promo-termos-e-condicoes"
  }, {
    path: "/contrate/sucesso/download-app",
    component: _42537274,
    name: "contrate-sucesso-download-app"
  }, {
    path: "/empresas/sections/SectionAccreditedNetwork",
    component: _5f150816,
    name: "empresas-sections-SectionAccreditedNetwork"
  }, {
    path: "/empresas/sections/SectionBenefits",
    component: _69ab21aa,
    name: "empresas-sections-SectionBenefits"
  }, {
    path: "/empresas/sections/SectionEcosystem",
    component: _42855aa1,
    name: "empresas-sections-SectionEcosystem"
  }, {
    path: "/empresas/sections/SectionFaq",
    component: _f10c4712,
    name: "empresas-sections-SectionFaq"
  }, {
    path: "/empresas/sections/SectionPlans",
    component: _1f53d8ea,
    name: "empresas-sections-SectionPlans"
  }, {
    path: "/empresas/sections/SectionReviews",
    component: _68ce2e7c,
    name: "empresas-sections-SectionReviews"
  }, {
    path: "/empresas/sections/SectionSupportContent",
    component: _4d88bc4e,
    name: "empresas-sections-SectionSupportContent"
  }, {
    path: "/empresas/sections/SectionWhyOffer",
    component: _3457efe3,
    name: "empresas-sections-SectionWhyOffer"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/confirmacao/:message?",
    component: _013de4d1,
    name: "confirmacao-message"
  }, {
    path: "/contrate/:id?",
    component: _461f8cb2,
    name: "contrate-id"
  }, {
    path: "/indicacao/:token?",
    component: _55ac032a,
    name: "indicacao-token"
  }, {
    path: "/localizador-pet/:id",
    component: _0bd30468,
    name: "localizador-pet-id"
  }, {
    path: "/promocoes/:index",
    component: _13e34e63,
    name: "promocoes"
  }, {
    path: "/rede-credenciada/*",
    component: _06971982,
    name: "rede-credenciada-all"
  }, {
    path: "/plano-de-saude-pet/*",
    component: _ba5c7522,
    name: "plano-de-saude-pet-all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
