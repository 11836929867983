import createPersistedState from 'vuex-persistedstate';

import { getItem, removeItem } from '@/utils/storage';

const storageKey = 'PL_additionalServices';
if (getItem(storageKey)) {
  window.sessionStorage.setItem(storageKey, getItem(storageKey));
  removeItem(storageKey);
}

export default ({ store }) => {
  createPersistedState({
    key: storageKey,
    reducer: (state) => ({
      additionalServices: state.additionalServices
    }),
    storage: {
      getItem: (key) => window.sessionStorage.getItem(key),
      setItem: (key, value) => window.sessionStorage.setItem(key, value),
      removeItem: (key) => window.sessionStorage.removeItem(key)
    }
  })(store);
};
