export const COMPANY_LEGAL_DATA = {
  corporateName: 'PETSUPERMARKET COMERCIO DE PRODUTOS PARA ANIMAIS LTDA',
  cnpj: '10.864.846/0033-00',
  municipalRegistration: '1.284.008-4',
  address: {
    street: 'Av. das Nações Unidas',
    number: '12901',
    neighborhood: 'Brooklin Paulista',
    state: 'SP',
    city: 'São Paulo',
    zipcode: '04.578-910'
  }
};
