import { render, staticRenderFns } from "./index.vue?vue&type=template&id=73871e4f&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=73871e4f&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73871e4f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CaIcon: require('/app/node_modules/@petlove/caramelo-vue-components/src/Icon/index.vue').default,CaDivisor: require('/app/node_modules/@petlove/caramelo-vue-components/src/Divisor/index.vue').default,CaLink: require('/app/node_modules/@petlove/caramelo-vue-components/src/Link/index.vue').default,CaButton: require('/app/node_modules/@petlove/caramelo-vue-components/src/Button/index.vue').default,Header: require('/app/components/pages/Buy/Header.vue').default})
