export default function ({ $axios }, inject) {
  const track = $axios.create({
    headers: {
      common: {
        Accept: 'application/json'
      }
    }
  });

  track.setBaseURL(process.env.TRACK_URL);
  inject('track', track);
}
