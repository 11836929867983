export const PAYMENT_METHODS = {
  credit_card: 'mensal_credito',
  bank_debit: 'mensal_debito',
  credit_card_yearly: 'anual_credito',
  bank_debit_yearly: 'anual_debito',
  bank_slip_yapay: 'anual_boleto',
  pix: 'anual_pix'
};

export function getPaymentMethodName(paymentMethod, yearly) {
  if (paymentMethod === 'credit_card') {
    return yearly
      ? PAYMENT_METHODS.credit_card_yearly
      : PAYMENT_METHODS.credit_card;
  } else if (paymentMethod === 'bank_debit') {
    return yearly
      ? PAYMENT_METHODS.bank_debit_yearly
      : PAYMENT_METHODS.bank_debit;
  } else if (paymentMethod === 'bank_slip_yapay') {
    return PAYMENT_METHODS.bank_slip_yapay;
  } else if (paymentMethod === 'pix') {
    return PAYMENT_METHODS.pix;
  }
}

export const PURCHASE = 'purchase';
export const USERDATA = 'userData';
export const HIRING = 'contratacao';
export const YEARLY = 'anual';
export const MONTHLY = 'mensal';
export const PLANS = 'planos';
