import { render, staticRenderFns } from "./simple.vue?vue&type=template&id=053193f1"
import script from "./simple.vue?vue&type=script&lang=js"
export * from "./simple.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NofaroToast: require('/app/components/NofaroToast.vue').default,SnackBar: require('/app/components/SnackBar.vue').default,HeaderSimple: require('/app/components/HeaderSimple.vue').default,FooterSimple: require('/app/components/footer/FooterSimple.vue').default,ModalUtmAlert: require('/app/components/modals/ModalUtmAlert.vue').default})
