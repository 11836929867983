
import { mapGetters } from 'vuex';

import URLS from '@/models/Url';
import Gtm from '@/utils/gtm';
import { HeadHome } from '@/utils/heads';
import { navigateToCheckout, persistNavigationQuery } from '@/utils/navigation';

export default {
  name: 'NavbarMobile',
  data() {
    return {
      HeadHome,
      showMenuMobile: false
    };
  },
  computed: {
    ...mapGetters({
      memberFromBroker: 'getMemberFromBroker'
    }),
    ecommerceUrl() {
      return URLS().ECOM_URL;
    },

    contractTitle() {
      return this.$route.path === '/empresas'
        ? 'Oferecer benefício'
        : 'Contratar agora';
    }
  },
  methods: {
    toContrate(from) {
      if (this.$route.path === '/empresas') {
        this.$nuxt.$emit('show:modal', 'requestBenefits');
        return;
      } else if (this.$route.path === '/') {
        location.href = navigateToCheckout({
          params: {
            from,
            ...this.$route.query
          }
        });
      } else {
        location.href = '/';
      }

      Gtm.sendCustomEvent(
        'click',
        'contratar-head:botao:contratar-agora',
        'lp-saude'
      );
    },
    headerLink() {
      const query = persistNavigationQuery(this.$route.query);
      const queryString = new URLSearchParams(query).toString();
      return this.$route.path === '/' ? this.ecommerceUrl : `/?${queryString}`;
    },
    openMenuMobile() {
      this.showMenuMobile = true;
    },
    closeSidenav() {
      this.showMenuMobile = false;
    }
  }
};
