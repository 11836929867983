import Vue from 'vue';

import { dinamicYieldApiSelectors } from '@/services/dynamicYield/campaigns';
import Gtm from '@/utils/gtm';

export default () => {
  try {
    sendGTMEventImpression();
  } catch (error) {
    console.error(error);
  }
};

export function sendGTMEventImpression() {
  try {
    const abTests = JSON.parse(window.localStorage.getItem('abTestsStorage'));
    if (!abTests) return;

    const { variantSelectorsPlans } = abTests;

    Gtm.sendDynamicYieldEvent(
      variantSelectorsPlans,
      dinamicYieldApiSelectors.selectorsPlans
    );
  } catch (error) {
    Vue?.prototype?.$honeybadger?.notify(error);
  }
}
