
import { HeadError } from '@/utils/heads';
import error404 from '~/components/errors/Error404.vue';
import error500 from '~/components/errors/Error500.vue';
import Track from '~/utils/track';

export default {
  name: 'NuxtError',
  layout: 'error',
  props: {
    error: {
      type: Object,
      default: () => {}
    }
  },
  head() {
    /*
      Foi adicionado um head no layout error, pq na verdade ele é tratado como uma página. Nenhuma outra página será renderizada a partir dele.
      Doc: https://nuxtjs.org/docs/directory-structure/layouts#error-page
    */
    return HeadError;
  },
  computed: {
    errorPage() {
      if (this.error.statusCode === 404) {
        return error404;
      }
      // catch everything else
      return error500;
    }
  },
  mounted() {
    Track.watchNotifyEvent(this.$root, this.userData);
  }
};
