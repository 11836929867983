import utils from '../utils/utils';

import VueMask from 'v-mask';
import Vue from 'vue';
import VueCookie from 'vue-cookie';
import Vuelidate from 'vuelidate';

Vue.use(VueMask);
Vue.use(Vuelidate);
Vue.use(VueCookie);

Vue.filter('formatBRL', utils.currency);
Vue.filter('formatDate', utils.formatDate);
