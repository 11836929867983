export default ({ $axios }, inject) => {
  const API = $axios.create({
    withCredentials: false,
    headers: {
      common: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }
  });

  API.setBaseURL(process.env.POSTS_URL);
  inject('postsApi', API);
};
