export const NETWORK_TYPE = [
  {
    name: 'Imagem',
    id: 1
  },
  {
    name: 'Laboratório',
    id: 2
  },
  {
    name: '24h',
    id: 3
  },
  {
    name: 'Anestesista',
    id: 4
  },
  {
    name: 'Volante de imagem',
    id: 5
  },
  {
    name: 'Consultório',
    id: 6
  },
  {
    name: 'Fisioterapia e/ou Acupuntura',
    id: 7
  },
  {
    name: 'Veterinário a domicílio',
    id: 8
  },
  {
    name: 'Especialista Volante',
    id: 9
  },
  {
    name: 'Clínica com Cirurgia',
    id: 10
  },
  {
    name: 'Petshop',
    id: 11
  }
];
