import { render, staticRenderFns } from "./index.vue?vue&type=template&id=19f6ce6c&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=19f6ce6c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19f6ce6c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CaIconButton: require('/app/node_modules/@petlove/caramelo-vue-components/src/IconButton/index.vue').default,CaButton: require('/app/node_modules/@petlove/caramelo-vue-components/src/Button/index.vue').default,MenuMobile: require('/app/components/header/NavbarMobile/MenuMobile.vue').default})
