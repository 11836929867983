
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      selectRegion: false,
      additional: false,
      requestAccreditation: false,
      beAffiliate: false,
      requestBenefits: false,
      benefitConfirmation: false,
      drawerSuccess: {
        show: false,
        name: ''
      },
      video: {
        show: false,
        youtubeId: ''
      }
    };
  },
  computed: {
    ...mapGetters({ regions: 'getRegions' })
  },
  created() {
    this.$nuxt.$off('show:modal');
    this.$nuxt.$on('show:modal', (modal) => {
      if (typeof modal === 'string') this[modal] = true;
      else this[modal.name] = { show: true, ...modal };
    });
    this.$nuxt.$off('hide:modal');
    this.$nuxt.$on('hide:modal', (modal) => {
      if (typeof modal === 'string') this[modal] = false;
      else this[modal.name] = { show: false, ...modal };
    });
    this.$nuxt.$off('toggle:modal');
    this.$nuxt.$on('toggle:modal', (modal) => {
      if (typeof modal === 'string') this[modal] = !this[modal];
      else this[modal.name] = { show: !this[modal.name], ...modal };
    });

    this.$nuxt.$off('show:video');
    this.$nuxt.$on('show:video', (youtubeId) => {
      this.video = { show: true, youtubeId };
    });
    this.$nuxt.$off('hide:video');
    this.$nuxt.$on('hide:video', (youtubeId) => {
      this.video = { show: false, youtubeId };
    });
    this.$nuxt.$off('toggle:video');
    this.$nuxt.$on('toggle:video', (youtubeId) => {
      this.video = { show: !this.video.show, youtubeId };
    });
  },
  beforeDestroy() {
    this.$nuxt.$off('show:modal');
    this.$nuxt.$off('hide:modal');
    this.$nuxt.$off('toggle:modal');
    this.$nuxt.$off('show:video');
    this.$nuxt.$off('hide:video');
    this.$nuxt.$off('toggle:video');
  }
};
