
import { mapGetters } from 'vuex';

import PetloveLogoPrincipal from '@/assets/images/petlove-logo-principal.svg';
import { CONTACTS } from '@/models/Contacts';
import URLS from '@/models/Url';
import Gtm from '@/utils/gtm';
import { HeadHome } from '@/utils/heads';
import { navigateToCheckout, persistNavigationQuery } from '@/utils/navigation';
import strings from '@/utils/strings';

export default {
  name: 'NavbarDesktop',
  components: {
    PetloveLogoPrincipal
  },

  props: {
    guestRegion: {
      type: Object,
      default: () => {},
      required: true
    }
  },

  data() {
    return {
      HeadHome,
      hoveredDepartment: null,
      hoverDelay: null,
      submenuItems: [
        {
          id: 1,
          title: 'Planos',
          link: ''
        },
        {
          id: 2,
          title: 'Para empresas',
          link: '/empresas'
        },
        {
          id: 3,
          title: 'Rede Credenciada',
          link: '/rede-credenciada'
        },
        {
          id: 4,
          title: 'Seja credenciado',
          link: '/credenciamento'
        }
      ],
      CONTACTS
    };
  },

  computed: {
    ...mapGetters({
      memberFromBroker: 'getMemberFromBroker'
    }),
    normalizedPhone() {
      return this.guestRegion?.st_cellphone?.replace(/\D/g, '');
    },

    menuItems() {
      return [
        {
          icon: 'help',
          title: 'Atendimento ao cliente',
          action: this.CONTACTS.sac,
          eventAction: 'menu:atendimento:botao:atendimento-ao-cliente'
        },
        {
          icon: 'whatsapp',
          title: this.guestRegion.st_cellphone
            ? `Vendas: ${this.guestRegion.st_cellphone}`
            : 'Vendas',
          action: `https://api.whatsapp.com/send?phone=55${this.normalizedPhone}&text=%22Oi,%20gostaria%20de%20saber%20mais%20sobre%20o%20plano%20de%20sa%C3%BAde%20da%20Petlove.%22`,
          eventAction: 'menu:atendimento:botao:whatssap-vendas'
        },
        {
          icon: 'doc-info',
          title: 'Termos promocionais',
          action:
            'https://petlovesaude.zendesk.com/hc/pt-br/categories/30125439499419',
          eventAction: 'menu:sobre:botao:termos-promocionais'
        }
      ];
    },
    toCustomersSpace() {
      Gtm.sendCustomEvent('click', 'menu:botao:espaco-cliente', 'menu-lp');
      return URLS().DASH_URL;
    },
    toPartnerSpace() {
      Gtm.sendCustomEvent('click', 'menu:botao:espaco-empresa', 'menu-lp');
      return URLS().PARTNERS_URL;
    },
    logoLink() {
      if (this.memberFromBroker) return;

      const query = persistNavigationQuery(this.$route.query);

      const queryString = new URLSearchParams(query).toString();

      return this.$route.path === '/' ? URLS().ECOM_URL : `/?${queryString}`;
    }
  },

  methods: {
    goToPlanSection() {
      const scroll = () => {
        this.$scrollTo('#planos-de-saude', 700, {
          offset: -104,
          x: false,
          y: true
        });
      };

      if (this.$route.path !== '/') {
        this.$router.push({
          path: '/',
          query: persistNavigationQuery(this.$route.query)
        });
        setTimeout(() => scroll(), 300);
      } else {
        scroll();
      }
    },

    handleSubMenu(submenuItem) {
      const submenuItemName = strings.stringToSlug(submenuItem?.title);
      Gtm.sendCustomEvent(
        'click',
        `menu:servicos:botao:${submenuItemName}`,
        'menu-lp'
      );
      const planSectionId = submenuItem.id === 1;

      if (planSectionId) {
        this.goToPlanSection();
      } else {
        this.$router.push(submenuItem.link);
      }
    },

    toContrate() {
      Gtm.sendCustomEvent('click', 'menu:botao:contratar-agora', 'menu-lp');

      location.href = navigateToCheckout({
        params: {
          from: 'headerCta',
          ...this.$route.query
        }
      });
    },

    selectRegion() {
      Gtm.sendCustomEvent('modal', 'modal-regiao', 'menu-lp');
      this.$nuxt.$emit('show:modal', 'selectRegion');
    },

    menuActions(menu) {
      Gtm.sendCustomEvent('click', menu.eventAction, 'menu-lp');
      window.open(menu.action, '_blank');
    }
  }
};
