import VueCookie from 'vue-cookie';

import UserService from '@/services/user';

class AuthService {
  constructor() {
    this.user = null;
  }

  getAuthHeaders() {
    const headers = {};

    const ssoPublicId = VueCookie.get('public_id');
    const ssoReferenceId = VueCookie.get('reference_id');
    const token = VueCookie.get('token');

    if (ssoPublicId && ssoReferenceId && token) {
      headers.common = {
        'public-id': ssoPublicId,
        'session-reference-id': ssoReferenceId,
        Authorization: `Bearer ${token}`
      };
    } else if (token) {
      headers.common = {
        Authorization: `Bearer ${token}`
      };
    }

    return headers;
  }

  getUser() {
    const user = window.localStorage.getItem('user');
    return user ? JSON.parse(user) : false;
  }

  getToken() {
    return VueCookie.get('token');
  }

  setToken(token) {
    VueCookie.set('token', token, {
      expires: '3M',
      domain: process.env.COOKIE_DOMAIN,
      secure: true
    });
  }

  setUser(user) {
    if (user) {
      window.localStorage.setItem('user', JSON.stringify(user), {
        expires: '3M',
        domain: process.env.COOKIE_DOMAIN
      });
    }
  }

  isLogged() {
    const token = this.getToken();
    return token !== null && token !== '';
  }

  logout() {
    window.localStorage.removeItem('user', {
      domain: process.env.COOKIE_DOMAIN
    });
    VueCookie.delete('token', {
      domain: process.env.COOKIE_DOMAIN
    });
    this.ssoLogout();
  }

  ssoLogout() {
    const publicId = VueCookie.get('public_id');
    const referenceId = VueCookie.get('reference_id');

    if (publicId || referenceId) {
      VueCookie.delete('public_id', {
        domain: process.env.COOKIE_DOMAIN
      });
      VueCookie.delete('reference_id', {
        domain: process.env.COOKIE_DOMAIN
      });

      const queryCallback = `?dcb=${window.location.href}`;
      const redirectTo = `${process.env.NUXT_ENV_SSO_URL}destroy/${queryCallback}`;
      window.location.replace(redirectTo);
    }
  }

  login(ctx, credentials) {
    return ctx.$axios
      .post('login/local', credentials)
      .then((res) => res.data)
      .then((res) => {
        this.setToken(res.data.accessToken);
        return this.setUserInfo(ctx);
      });
  }

  setUserInfo(ctx) {
    return new UserService().getUserInfo(ctx).then((data) => {
      this.setUser(data);
      return data;
    });
  }
}

export default AuthService;
