
import {
  email,
  maxLength,
  minLength,
  required
} from 'vuelidate/lib/validators';
import { mapActions, mapMutations } from 'vuex';

import { COMPANY_SIZE } from '@/models/CompanySize';
import { isNotUndefinedOrNullOrEmptyOrZero } from '@/services/string';
import Strings from '@/utils/strings';

const emptyForm = {
  companyName: '',
  companySector: '',
  companySize: '',
  name: '',
  companyEmail: '',
  phone: ''
};

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      COMPANY_SIZE,
      loading: false,
      form: { ...emptyForm }
    };
  },
  computed: {
    splitTelephone() {
      const telephone = this.form.phone;
      const ddd = Strings.sanitizePhone(telephone.slice(1, 3));
      const number = Strings.sanitizePhone(telephone.slice(5, 15));
      return { ddd, number };
    }
  },
  methods: {
    ...mapActions(['clearRequestBenefitsLP']),
    ...mapMutations(['request_benefits']),

    requestBenefits() {
      const success = {
        name: 'drawerSuccess',
        header: 'Informações enviadas',
        headerIcon: 'paw-heart',
        title: 'Informações enviadas com sucesso!',
        description: `<p class="text-center">Agora é só aguardar! Vamos entrar em contato dentro de 24h com mais informações sobre o seu cadastro.</p>`
      };

      const newForm = {
        ...this.form,
        ...this.splitTelephone
      };

      this.$v.$touch();
      if (!this.$v.form.$invalid) {
        try {
          this.request_benefits(newForm);
          this.$root.$emit('notify-event', 'oferecer_beneficio');
          this.$nuxt.$emit('show:modal', success);
          this.$emit('close');
          this.clearForm();
          this.clearRequestBenefitsLP();
        } catch (error) {
          this.$emit('close');
          this.clearForm();
          this.$root.$emit('snackbar:error', 'Erro ao enviar informações.');
        }
      }
    },

    clearForm() {
      this.$v.$reset();
      this.form = { ...emptyForm };
    }
  },
  validations() {
    return {
      form: {
        companyName: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(150),
          isNotUndefinedOrNullOrEmptyOrZero
        },
        companyEmail: {
          required,
          email,
          minLength: minLength(2),
          maxLength: maxLength(150),
          isNotUndefinedOrNullOrEmptyOrZero
        }
      }
    };
  }
};
