export const isBundleRegions = (region = null) => {
  if (typeof window !== 'undefined' && window.$nuxt) {
    const getters = window.$nuxt.$store?.getters;
    const isUtmAndPartner =
      getters?.getParceiro?.status && getters?.getPromoCode;
    const guestRegion = region ?? getters?.getGuestRegion;
    const notAllowedRegions =
      getters['additionalServices/getDiscountClubDisabledRegions'];

    if (isUtmAndPartner) {
      return false;
    }

    const isRegionNotAllowed = notAllowedRegions?.includes(guestRegion?.id);

    return !isRegionNotAllowed;
  }
  return false;
};
