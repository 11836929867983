
const ONE_MINUTE = 60000;
export default {
  data() {
    return {
      visible: false,
      props: {
        message: ''
      }
    };
  },
  created() {
    this.$root.$on('snackbar:show', (props) => {
      if (typeof props === 'string') this.show({ message: props });
      else this.show(props);
    });

    this.$root.$on('snackbar:error', (message) => {
      const msg = typeof message === 'object' ? message.message : message;
      this.show({
        type: 'negative',
        btnLabel: 'Fechar',
        duration: ONE_MINUTE,
        message: msg
      });
    });
  },
  beforeDestroy() {
    this.$root.$off('snackbar:show');
    this.$root.$off('snackbar:error');
  },
  methods: {
    show(props) {
      this.props = {
        ...props
      };
      this.visible = true;
    },
    close() {
      this.props?.close && this.props.close();
      this.visible = false;
    },
    click() {
      this.props?.click && this.props.click();
      this.$refs.snackbar.close();
    }
  }
};
