export default ({ $axios }, inject) => {
  const API = $axios.create({
    withCredentials: false,
    headers: {
      'x-service-name': 'health',
      'x-bu-origin': 'health',
      common: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      cookie: ''
    }
  });

  API.setBaseURL(process.env.API_GATEWAY_URL);
  inject('apiGateway', API);
};
