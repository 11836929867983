
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: true,
      procedures: []
    };
  },
  computed: {
    guestRegion() {
      const guestRegion = this.$store.state.guestRegion;
      if (!guestRegion) {
        return false;
      }
      return guestRegion;
    }
  },
  watch: {
    show() {
      this.setProcedures();
    }
  },
  methods: {
    onClose() {
      this.$nuxt.$emit('hide:modal', 'additional');
    },
    setProcedures() {
      this.$axios
        .$get(`regions/${this.guestRegion.slug}/additionals?group=1`)
        .then((coverage) => {
          this.procedures = coverage?.map((category) => ({
            ...category,
            procedures: category?.procedures?.map((proc) => ({
              ...proc
            }))
          }));
          this.loading = false;
        });
    }
  }
};
