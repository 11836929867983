import HoneybadgerVue from '@honeybadger-io/vue';
import Vue from 'vue';

const config = {
  apiKey: process.env.HONEYBADGER_API_KEY,
  environment: process.env.APP_ENV,
  developmentEnvironments: ['dev', 'development', 'test', 'staging'],
  revision: 'main',
  maxObjectDepth: 10,
  filters: [
    'creditcard',
    'credit_card',
    'bankdebit',
    'bank_debit',
    'cvv',
    'password',
    'token'
  ]
};

Vue.use(HoneybadgerVue, config);

if (process.client) {
  window.addEventListener('unhandledrejection', (event) => {
    const honeybadger = Vue?.prototype?.$honeybadger;
    const error = event?.reason;

    if (error?.isAxiosError) {
      return;
    }

    if (error?.response?.status >= 400 && error?.response?.status < 500) {
      return;
    }

    if (!honeybadger) {
      console.error('Honeybadger not initialized:', {
        error,
        stack: error?.stack
      });
      return;
    }

    honeybadger.notify(error, {
      context: {
        error: {
          name: error?.name,
          message: error?.message,
          type: error?.constructor?.name,
          stackTrace: error?.stack,
          statusCode: error?.response?.status
        },
        request: error?.config && {
          url: error?.config?.url,
          method: error?.config?.method,
          baseURL: error?.config?.baseURL
        },
        vue: {
          component:
            event?.target?._vnode?.context?.$options?.name || 'Unknown',
          route: window?.$nuxt?.$route?.path,
          routeParams: window?.$nuxt?.$route?.params,
          routeQuery: window?.$nuxt?.$route?.query
        },
        environment: {
          timestamp: new Date().toISOString(),
          userAgent: window?.navigator?.userAgent,
          url: window?.location?.href,
          previousUrl: document?.referrer
        }
      },
      name: 'UnhandledPromiseRejection',
      fingerprint: `${error?.name}-${error?.message}-${window?.$nuxt?.$route?.path}`
    });
  });
}
