export function isUndefinedOrNullOrEmptyOrZero(param) {
  return param === undefined || param === null || param === '' || param === '0';
}

export function isNotUndefinedOrNullOrEmptyOrZero(param) {
  return !isUndefinedOrNullOrEmptyOrZero(param);
}

export function isUndefinedOrNull(param) {
  return param === undefined || param === null;
}

export function isNotUndefinedOrNull(param) {
  return !isUndefinedOrNull(param);
}

export function isUndefinedOrNullOrEmpty(param) {
  return param === undefined || param === null || param === '';
}

export function isNotUndefinedOrNullOrEmpty(param) {
  return !isUndefinedOrNullOrEmpty(param);
}
