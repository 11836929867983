import createPersistedState from 'vuex-persistedstate';

const storageKey = 'abTestsStorage';

export default ({ store }) => {
  createPersistedState({
    key: storageKey,
    reducer: (state) => {
      return {
        showBundleAddOn: state.showBundleAddOn,
        checkoutDefault: state.checkoutDefault,
        variantClubPrice: state.variantClubPrice,
        variantSelectorsPlans: state.variantSelectorsPlans
      };
    },
    storage: {
      storage: window.localStorage,
      getItem: (key) => window.localStorage.getItem(key),
      setItem: (key, value) => window.localStorage.setItem(key, value),
      removeItem: (key) => window.localStorage.removeItem(key)
    }
  })(store);
};
