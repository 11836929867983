
import PhotoCellSvg from '@/assets/images/illustrations-ds/photo-cell.svg';

export default {
  components: {
    PhotoCellSvg
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      inputQuery: '',
      selectedRegion: ''
    };
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    benefitConfirmation() {
      this.onClose();
    }
  }
};
