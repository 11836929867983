// Used to delay any function
const onCompleteLoad = (callback) => {
  if (document.readyState === 'complete') {
    callback();

    window.removeEventListener('load', callback);
  } else {
    window.addEventListener(
      'load',
      () => {
        callback();
      },
      { passive: true }
    );
  }
};

module.exports = { onCompleteLoad };
