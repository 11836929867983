import axios from 'axios';
import Vue from 'vue';

import Gtm from '@/utils/gtm';

const CONTROL_GROUP = 'control group';

const API = axios.create({
  baseURL: process.env.BASE_URL_DY,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'DY-API-Key': process.env.DY_API_KEY
  }
});

const OTHER_PAGE_TYPE = 'OTHER';
const LOCALE = 'en_US';

export async function dynamicYieldCampaign(
  stateVariant,
  campaignName,
  commitVariant
) {
  if (stateVariant) {
    return stateVariant;
  }
  try {
    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => reject(new Error('DY Timeout')), 5000);
    });

    const dyPromise = API.post('/v2/serve/user/choose', {
      user: { active_consent_accepted: false },
      context: {
        page: {
          type: OTHER_PAGE_TYPE,
          location: window.location.href,
          locale: LOCALE,
          data: []
        },
        device: {
          userAgent: window.navigator.userAgent
        }
      },
      selector: { names: [campaignName] }
    });

    const response = await Promise.race([dyPromise, timeoutPromise]);

    const variations = response.data.choices[0]?.variations;
    if (!variations || variations.length === 0) {
      return null;
    }

    const variant = variations[0]?.payload?.data?.variant ?? CONTROL_GROUP;
    window.$nuxt.$store.commit(commitVariant, variant);

    if (variant && window.clarity) {
      if (campaignName.includes('EXP')) {
        window.clarity('set', 'Testes A/B - Saúde Experiência', campaignName);
      } else {
        window.clarity('set', 'Testes A/B - Saúde Aquisição', campaignName);
      }
    }

    Gtm.sendDynamicYieldEvent(variant, campaignName);
    return variant;
  } catch (error) {
    Vue.prototype.$honeybadger.notify(error, {
      context: {
        service: 'DynamicYield',
        campaign: campaignName,
        errorMessage: error?.message,
        errorType: error?.constructor?.name,
        errorStack: error?.stack
      },
      name: 'DynamicYieldError'
    });
    return null;
  }
}
